<div class="dropdown navbar-dropdown-link open menu-item">
  <div class="dropdown-toggle" data-toggle="dropdown">
    <div class="profile-logo">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
        x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
        width="20px" height="20px">
        <g>
          <g>
            <path
              d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40    c59.551,0,108,48.448,108,108S315.551,256,256,256z"
              fill="#FFFFFF" />
          </g>
        </g>
      </svg>
    </div>
    <i class="fa fa-angle-down fa-fw text-gray profile-down-arr" aria-hidden="true"></i>
  </div>
  <div class="dropdown-menu mega-menu-main profile-menu">
    <a class="dropdown-item menu-lbl d-flex flex-column" routerLink="/profile">
      Profile 
      <div class="d-flex last_login fnt-12" *ngIf="currentUser?.last_login?.time">
        <img src="../../../../assets/lastLogin.webp" alt="last login" class="mar-right-2 icon" />
        Last Login:
        <div class="fnt-11 text mar-left-3">
          {{moment(currentUser.last_login.time).format("DD MM YYYY, h:mm:ss a")}}
        </div>
      </div>
    </a>
    <a class="dropdown-item sub-main-lbl">Manage Bookings</a>
    <a class="dropdown-item sub-lbl" routerLink="/golf-bookings">
      <i class="fa fa-angle-right select-down"></i>
      <span>Golf Booking Requests</span>
    </a>
    <a class="dropdown-item sub-lbl" routerLink="/airport-concierge-service-bookings">
      <i class="fa fa-angle-right select-down"></i>
      <span>Airport Concierge Service</span>
    </a>
    <a class="dropdown-item sub-lbl" routerLink="/airport-transfer-bookings">
      <i class="fa fa-angle-right select-down"></i>
      <span>Airport Transfer Bookings</span>
    </a>
    <a class="dropdown-item menu-lbl" (click)="logout()">Sign Out</a>
  </div>
</div>