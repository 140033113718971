import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../services/common-services";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { User } from "../services/user";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import * as CryptoJS from "crypto-js";
import { ToastrService } from "ngx-toastr";
import { RazorpayPgService } from "@pv-frontend/razorpay-pg"

declare var $: any;
declare var jquery: any;

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
  providers: [DatePipe],
})
export class PaymentComponent implements OnInit {
  paymentForm: UntypedFormGroup;
  savedCardForm: UntypedFormGroup;
  enquiryId: any;
  bookingDate: any;
  bookingTime: any;
  allMonth: any = [];
  allYear: any = [];
  paymentType: any = "Prefered";
  cardData: any;
  userData: any;
  cardError: boolean;
  currentUser: any;
  cardInvalid: boolean = false;
  openAddNewCard: boolean = false;
  payiconClass: any = "";
  networkLogo: any;
  saveCard: boolean = true;
  storedCards: any = [];
  cards: any = [];
  storedCardsCount: number = 0;
  selectedCard: any;
  makingPayment: boolean = false;
  @Input() payingFor;
  @Input() bookingId;
  @Input() amountPayable;
  cardInfoRequested: boolean = false;
  paymentClicked: boolean = false;
  paymentModalClosed: boolean = false;

  constructor(
    private user: User,
    private _commonService: CommonService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    public meta: Meta,
    public pageTitle: Title,
    private router: Router,
    private toastr: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private pgService: RazorpayPgService
  ) {
    var userData = this._commonService.getUser();
    // if(userData){
    //   this._commonService.getUserData().subscribe(response =>{
    //     this.userData = response;
    //   })
    // }
    // else {
    //   // this.router.navigate(["golf"]);
    // }
    this.paymentForm = fb.group({
      name: ["", Validators.required],
      card: [
        "",
        [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(27),
        ],
      ],
      expiryMonth: [
        "",
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      cvv: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });
    this.savedCardForm = fb.group({
      cvv: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });
    this.paymentForm.get("card").valueChanges.subscribe((mode: string) => {
      if (mode && mode.replace(/ /g, "").length == 8) {
        var data = {
          bin: mode.replace(/ /g, ""),
        };
        if(!this.cardData && !this.cardInfoRequested) {
          this.cardInfoRequested = true;
          this._commonService.getCardInfo(data).subscribe(
            (response) => {
              if (response.status !== 0) {
                this.cardError = false;
                this.cardData = response;
              } else {
                this.cardError = true;
              }
              this.cardInfoRequested = false;
            },
            (err) => {
              this.cardInfoRequested = false;
              this.cardError = true;
              this.cardData = null;
            }
          );
        }
      }
    });
    this.pgService.paymentModalClosedEvent.subscribe((isClosed: any) => {
      console.log(isClosed, "isClosed");
      this.makingPayment = !isClosed;
      this.paymentClicked = !isClosed;
      this.changeDetector.detectChanges();
    })
  }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, 0);
    $('[data-toggle="tooltip"]').tooltip();
    // this.getStoredCards();
    let user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
    }
  }

  getStoredCards() {
    this._commonService.getStoredCards().then((response) => {
      if (response.status == 1) {
        this.storedCards = Object.values(response.user_cards);
      } else {
        this.storedCards = [];
      }
      this.storedCardsCount = this.storedCards.length;
      this.cards = this.storedCards;
      if (this.storedCardsCount > 0) {
        this.paymentType = "Prefered";
      } else {
        this.paymentType = "Credit";
      }
    });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  toggleSaveCard(e) {
    this.saveCard = !this.saveCard;
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateCvv(event, value) {
    if (!this.isNumber(event) || (value && value.length > 3)) {
      return false;
    }
  }

  validateMobile(form, field, event, len) {
    if (
      !this.isNumber(event) ||
      (form.get(field).value ? form.get(field).value.length : 0) > len - 1
    ) {
      return false;
    }
  }
  changeExp(event) {
    let exMonth =
      this.paymentForm.value.expiryMonth &&
      this.paymentForm.value.expiryMonth.split("/").join("");
    exMonth = exMonth && exMonth.match(new RegExp(".{1,2}", "g")).join("/");
    this.paymentForm.controls["expiryMonth"].setValue(exMonth);
    // let expiry = this.paymentForm.controls['expiryMonth'].value;
    // if (expiry.length === 2) {
    //   this.paymentForm.controls['expiryMonth'].setValue(expiry + '/');
    // }
  }
  mychange(event) {
    const self = this;
    let card = event.target.value.replace(" ", "");
    let chIbn = this.paymentForm.value.card.split("  ").join("");
    if (card.length > 0) {
      chIbn = chIbn.match(new RegExp(".{1,4}", "g")).join("  ");
      if (card[0] == "5") {
        this.networkLogo = "../../assets/master.png";
        this.payiconClass = "master-ic";
      } else if (card[0] == "4") {
        this.networkLogo = "../../assets/visa.png";
        this.payiconClass = "visa-ic";
      } else if (card[0] == "6") {
        this.networkLogo = "../../assets/RuPay.png";
        this.payiconClass = "rupay-ic";
      } else if (card.substring(0, 2) == "34" || card.substring(0, 2) == "37") {
        this.networkLogo = "../../assets/icons/amex-512.png";
        this.payiconClass = "amex-ic";
      }
      // if(card.length >= 7 && !this.cardData){
      //   var data = {
      //           bin: card.replace(" ","").substring(0,6)
      //        };
      //   this._commonService.getCardInfo(data).subscribe(response =>{
      //          if(response.status=="success"){
      //            this.cardData = response.data;
      //            console.log(response);
      //          }
      //        },err=>{
      //          this.cardError = true;
      //        });
      // }
      // else if(card.length < 7){
      //   this.cardData = undefined;
      // }
    } else {
      this.networkLogo = undefined;
    }
    this.paymentForm.controls["card"].setValue(chIbn);
  }

  showPaymentType(payType) {
    this.paymentType = payType;
  }

  async saveCards(cardData) {
    await this._commonService.saveCards(cardData).then((resp) => {
      return resp;
    });
  }

  isInteger(x) {
    return typeof x === "number" && isFinite(x) && Math.floor(x) === x;
  }

  sendPayuRequest(payment_create_response, bookingId) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);
    payment_create_response.payu_body.ccvv = this.decrypt(
      payment_create_response.payu_body.ccvv,
      this.generateKey(bookingId)
    );
    Object.keys(payment_create_response.payu_body).forEach((key) => {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      if(key == "ccnum") {
        hiddenField.setAttribute("value", this.paymentForm.value.card.split("  ").join(""));
      } else {
        hiddenField.setAttribute("value", payment_create_response.payu_body[key]);
      }
      form.appendChild(hiddenField);
    });
    document.body.appendChild(form);
    form.submit();
  }

  generateKey(str: string) {
    if (str.length < 32) {
      let value = str;
      for (let i = str.length; i < 32; i++) {
        value = value + "0";
      }
      return value;
    } else {
      return str.substr(0, 32);
    }
  }

  encrypt(data: string, encryptionKey: string): string {
    const iv = CryptoJS.enc.Utf8.parse("7061737323313233");

    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Utf8.parse(encryptionKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
  }

  decrypt(ciphertext: string, decryptionKey: any): string {
    const iv = CryptoJS.enc.Utf8.parse("7061737323313233");

    const decrypted = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(decryptionKey),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  getProductInfo() {
    if (this.payingFor == "golf") {
      return "golf_enquiry";
    } else if (this.payingFor == "headout") {
      return "toursActivities";
    } else if (this.payingFor == "luxury-hotels") {
      return "hotels";
    } else if (this.payingFor == "airport") {
      return "airport_concierge_enquiry";
    } else if (this.payingFor == "airport-transfer") {
      return "airport_transfer";
    }
  }

  getPaymentAmount() {
    if (this.payingFor == "golf") {
      return 10;
    } else if (this.payingFor == "airport") {
      return 1;
    } else if (this.payingFor == "airport-transfer") {
      return 1;
    }
  }

  makePaymentRequest(data, savedCard) {
    data.cvv = parseInt(data.cvv);
    this.makingPayment = true;
    let cardInfo = {
      card_type: "",
      card_mode: "",
      payment_type: "",
      bank_code: "",
      card_token: "",
    };

    if (!savedCard && this.cardData) {
      cardInfo.card_type = this.cardData.cardType;
      cardInfo.card_mode = this.cardData.cardCategory;
      cardInfo.payment_type = this.cardData.cardCategory;
      cardInfo.bank_code = this.cardData.cardCategory == "CC" ? "CC" : "DC";
    }
    if (savedCard) {
      cardInfo.card_type = data.card_type;
      cardInfo.card_mode = data.card_mode;
      cardInfo.payment_type = data.card_mode;
      cardInfo.bank_code = data.card_mode == "CC" ? "CC" : "DC";
      cardInfo.card_token = data.card_token;
    }
    if (this.cardData && this.cardData.cardCategory) {
      let finalDetails: any = {};
      finalDetails.amount = this.getPaymentAmount();
      finalDetails.booking_id = location.pathname.split("/")[2];
      if(this.currentUser) {
        finalDetails.email = this.currentUser.email;
        finalDetails.first_name = this.currentUser.full_name;
        finalDetails.phone = this.currentUser.mobile;
      }
      finalDetails.product_info = this.getProductInfo();
      let transactions_attributes = [];
      transactions_attributes.push({
        amount: this.getPaymentAmount(),
        bank_code: cardInfo.bank_code,
        card_mode: cardInfo.card_mode,
        card_type: cardInfo.card_type,
        ccnum: this.encrypt(data.card_number, this.generateKey(finalDetails.booking_id)),
        ccname: data.name,
        encrypted_ccvv: this.encrypt(
          data.cvv,
          this.generateKey(finalDetails.booking_id)
        ),
        expiry_month: data.expiry_month,
        expiry_year: moment(data.expiry_year, "YY").format("YYYY"),
        payment_type: cardInfo.payment_type,
        txn_type: "payment_gateway",
      });
      finalDetails["transactions_attributes"] = transactions_attributes;
      if(data.card_token) {
        finalDetails.transactions_attributes[0] = {...finalDetails.transactions_attributes[0], ...{card_token: data.card_token}};
      }
      try {
        this.pgService.makePayment(finalDetails); 
      } catch (error) {
        if(error && (typeof(error) == 'string')) {
          this.toastr.error(error);
        }
        this.makingPayment = false;
      }
    } else {
      var data1;
      if(data.card_number) {
        data1 = {
          bin: data.card_number.slice(0, 8),
        };
      } else if(data.card_no) {
        data1 = {
          bin: data.card_no.slice(0, 8),
        };
      }
      this.cardError = false;
      let finalDetails: any = {};
      finalDetails.booking_id = location.pathname.split("/")[2];
      finalDetails.amount = this.getPaymentAmount();
      finalDetails.phone = this.currentUser.mobile;
      finalDetails.email = this.currentUser.email;
      finalDetails.first_name = this.currentUser.full_name;
      finalDetails.product_info = this.getProductInfo();
      let transactions_attributes = [];
      transactions_attributes.push({
        txn_type: "payment_gateway",
        amount: this.getPaymentAmount(),
        payment_type: cardInfo.payment_type,
        bank_code: cardInfo.bank_code,
        card_type: cardInfo.card_type,
        card_mode: cardInfo.card_mode,
        ccnum: this.encrypt(data.card_number || data.card_no, this.generateKey(finalDetails.booking_id)),
        ccname: data.card_name,
        encrypted_ccvv: this.encrypt(
          data.cvv,
          this.generateKey(finalDetails.booking_id)
        ),
        expiry_month: data.expiry_month,
        expiry_year: moment(data.expiry_year, "YY").format("YYYY"),
      });
      finalDetails["transactions_attributes"] = transactions_attributes;
      if(data.card_token) {
        finalDetails.transactions_attributes[0] = {...finalDetails.transactions_attributes[0], ...{card_token: data.card_token}};
      }
      try {
        this.pgService.makePayment(finalDetails); 
      } catch (error) {
        if(error && (typeof(error) == 'string')) {
          this.toastr.error(error);
        }
        this.makingPayment = false;
      }
    }
  }

  sendPaymentSaved(card, CardDet, bin) {
    //
    // var data = {
    //   card_token: card,
    //   cvv: cvv,
    //   type: 'savedCard',
    //   bin: bin,
    //   enquiry_id: this.bookingId
    // }
    this.makePaymentRequest(CardDet, true);
  }

  sendPayment() {
    this.cardInvalid = false;
    var data = {
      name: this.paymentForm.value.name,
      card_number: this.paymentForm.value.card.split("  ").join(""),
      expiry_month: this.paymentForm.value.expiryMonth.substring(0, 2),
      expiry_year: this.paymentForm.value.expiryMonth.substring(3, 5),
      cvv: this.paymentForm.value.cvv,
      // save: this.saveCard, //will not be calling save card api 
      type: "card",
      enquiry_id: this.bookingId,
    };
    this.makePaymentRequest(data, false);
  }

  openCardDrop() {
    this._commonService.toggleShowingEligibleCards();
  }

  clikedOpenAddNewCard() {
    this.openAddNewCard = true;
    this.cardData = null;
    this.paymentForm.reset();
  }
}
