import {
  Component,
  OnInit,
  NgModule,
  Inject,
  Pipe,
  PipeTransform,
  Output,
  EventEmitter,
  Input,
  ElementRef,
} from "@angular/core";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { CommonService } from "../../common/services/common-services";
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
// import { debug } from 'util';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import * as _moment from "moment";
import { AuthenticationComponent } from "./../../common/authentication/authentication.component";
import { DataSharingService } from "./../../common/services/data-sharing.service";
import * as moment from "moment";
import { GlobalConstants } from "../../common/services/global-variables";

// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

declare var $: any;

export interface DialogData {}

@Component({
  selector: "app-golf",
  templateUrl: "./golf.component.html",
  styleUrls: ["./golf.component.scss"],
  providers: [CommonService],
})
export class GolfComponent implements OnInit {
  currentUser: any;
  userData: any;
  cardValidatingFamiliar: boolean = false;
  cardValidating: boolean = false;
  golfCoursesForFamiliarUsers: any;
  golfCoursesToLearn: any;
  title =
    "Special Golf Offers on Axis Bank Premium Cards - Extraordinary Weekends";
  resetHeader: boolean = true;

  constructor(
    public dialog: MatDialog,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public data: DataSharingService,
    public meta: Meta,
    public pageTitle: Title
  ) {}

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    var title =
      "Book golf games or lessons at picturusque courses with complimentary rounds of golf - Axis Bank Extraordinary Weekends";
    var des =
      "Book golf games or lessons at picturusque courses with complimentary rounds of golf - Axis Bank Extraordinary Weekends";
    this.pageTitle.setTitle(title);
    this.meta.updateTag({ name: "description", content: des });
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params.code == 1) {
          this.toastr.success("Enquiry submitted successfully.");
        } else if (params.code == 0) {
          this.toastr.warning(
            "Something went wrong! Please try again after sometime."
          );
        }
      });
    });
  }

  scroll(el) {
    let elm = document.getElementById(el);
    elm.scrollIntoView();
    window.scrollBy(0, -110);
  }

  passGolfCoursesToParent(golfCourses, isForFamiliarUsers) {
    if (isForFamiliarUsers) {
      this.golfCoursesForFamiliarUsers = golfCourses;
    } else {
      this.golfCoursesToLearn = golfCourses;
    }
  }

  cardbinValidate(bin_number, isFamiliar) {
    if (bin_number.length == 8) {
      this._commonService.golfBinValidate(bin_number).subscribe(
        (response) => {
          if (response.status != "error") {
            if (isFamiliar) {
              this.cardValidatingFamiliar = false;
              const dialogRef = this.dialog.open(DialogBookgolfFamiliar, {
                data: {
                  bookings_count: response.data,
                  passGolfCoursesToParent: (a, b) =>
                    this.passGolfCoursesToParent(a, b),
                  golfCoursesForFamiliarUsers: this.golfCoursesForFamiliarUsers,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                console.log(`Dialog result: ${result}`);
              });
            } else {
              this.cardValidating = false;
              const dialogRef = this.dialog.open(DialogBookgolf, {
                data: {
                  bookings_count: response.data,
                  passGolfCoursesToParent: (a, b) =>
                    this.passGolfCoursesToParent(a, b),
                  golfCoursesToLearn: this.golfCoursesToLearn,
                },
              });

              dialogRef.afterClosed().subscribe((result) => {
                console.log(`Dialog result: ${result}`);
              });
            }
          } else {
            this.toastr.error(response.msg);
            this.cardValidatingFamiliar = false;
            this.cardValidating = false;
          }
        },
        (err) => {
          this.cardValidatingFamiliar = false;
          this.cardValidating = false;
          if (err?.status == 401) {
            this.showLoginToast();
          } else {
            this.toastr.warning(
              "Something went wrong! Please try again after sometime."
            );
          }
        }
      );
    }
  }

  showLoginToast() {
    this.toastr.error("Please login to continue.");
    this.resetHeader = false;
    setTimeout(() => {
      this.resetHeader = true;
    }, 100);
  }

  bookGolf(experience): void {
    let user = null;

    user = this._commonService.getUser();
    this.currentUser = JSON.parse(user);
    if (this.currentUser) {
      this.cardValidating = true;
      this.cardbinValidate(this.currentUser.bin, false);
    } else {
      this.showLoginToast();
      this.dialog
        .open(AuthenticationComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            this.currentUser = JSON.parse(this._commonService.getUser());
            var user = JSON.parse(this._commonService.getUser());
            if (user && user.bin) {
              this.cardbinValidate(user.bin, false);
            }
          }
        });
    }
  }

  bookGolfFamiliar(experience): void {
    let user = null;
    user = this._commonService.getUser();
    this.currentUser = JSON.parse(user);
    if (this.currentUser) {
      this.cardValidatingFamiliar = true;
      this.cardbinValidate(this.currentUser.bin, true);
    } else {
      this.showLoginToast();
      this.dialog
        .open(AuthenticationComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            this.currentUser = JSON.parse(this._commonService.getUser());
            var user = JSON.parse(this._commonService.getUser());
            if (user && user.bin) {
              this.cardbinValidate(user.bin, false);
            }
          }
        });
    }
  }
}

@Component({
  selector: "dialog-bookgolf",
  templateUrl: "./dialog-bookgolf.html",
  styleUrls: ["./golf.component.scss"],
  providers: [
    CommonService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    GlobalConstants
  ],
})
export class DialogBookgolf {
  golf_experience = false;
  showGolfFields = true;
  invalidBin = false;
  resErrorMessage = "";
  showError = false;
  emailPattern: any;
  mobilePattern: any;
  bookGolfForm: any;
  golfUpdate: any;
  currentUser: any;
  userData: any;
  golfError: string = "";
  binDataLoading: any = false;
  formSubmited: any = false;
  golfCourses: any;
  fetchingGolfCourses: any = false;
  makingEnquiry: boolean = false;

  dateFilter = (date: Date): boolean => {
    let dateNow = _moment().add(this.globalConstants.GOLF_BOOKING_ALLOWED_BEFORE_DAYS, "days");
    return _moment(date) > dateNow;
  };

  selectedCourse: any = {
    name: "",
    id: "",
  };
  bookingForOther = false;
  bookingFor(i) {
    this.bookingForOther = i == true ? true : false;
  }
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogBookgolf>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private el: ElementRef,
    public globalConstants: GlobalConstants
  ) {
    this.emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.mobilePattern = /^\d{10}$/;

    this.bookGolfForm = this.fb.group({
      golf_enquiry_bin: ["", [Validators.required]],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      mobile_number: [
        "",
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      golf_course_option: ["", Validators.required],
      booking_date_golf: ["", Validators.required],
      booking_time: ["", Validators.required],
      agree_terms: ["", Validators.required],
      contact_info: [""],
      user_type: ["", Validators.required],
      booking_for: ["Self", Validators.required],
      guest_name: [""],
    });

    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
      // this._commonService.getUserData().subscribe(response =>{
      this.userData = this.currentUser;
      if (this.userData.full_name) {
        let nameArr = this.userData.full_name.split(" ");
        if (nameArr[0]) {
          this.bookGolfForm.controls.first_name.setValue(nameArr[0]);
          this.bookGolfForm.controls.first_name.disable();
        }
        if (nameArr && nameArr.length > 1) {
          this.bookGolfForm.controls.last_name.setValue(
            nameArr[nameArr.length - 1]
          );
          this.bookGolfForm.controls.last_name.disable();
        }
      }
      this.bookGolfForm.controls["mobile_number"].setValue(
        this.userData.mobile
      );
      this.bookGolfForm.controls["email"].setValue(this.userData.email);
      this.bookGolfForm.controls["golf_enquiry_bin"].setValue(
        this.userData.bin
      );
      // this.cardbinValidate(this.userData.bin);
      // })
    }
  }

  ngAfterViewInit() {
    if (
      Array.isArray(this.data.golfCoursesToLearn) &&
      this.data.golfCoursesToLearn.length > 0
    ) {
      this.golfCourses = this.data.golfCoursesToLearn;
    } else {
      try {
        this.fetchingGolfCourses = true;
        this._commonService.getGolfCourses("learn").then((response) => {
          if (
            response.data &&
            response.data.data &&
            Array.isArray(response.data.data) &&
            response.data.data.length > 0
          ) {
            this.golfCourses = response.data.data;
            this.data.passGolfCoursesToParent(response.data.data, false);
          } else {
            this.toastr.error("There was an issue while fetching golf courses");
            this.dialogRef.close();
          }
          this.fetchingGolfCourses = false;
        });
      } catch (error) {
        this.fetchingGolfCourses = false;
        this.toastr.error("There was an issue while fetching golf courses");
        this.dialogRef.close();
      }
    }
  }

  get golf_enquiry_bin() {
    return this.bookGolfForm.get("golf_enquiry_bin");
  }
  get first_name() {
    return this.bookGolfForm.get("first_name");
  }
  get last_name() {
    return this.bookGolfForm.get("last_name");
  }
  get mobile_number() {
    return this.bookGolfForm.get("mobile_number");
  }
  get email() {
    return this.bookGolfForm.get("email");
  }
  get golf_course_option() {
    return this.bookGolfForm.get("golf_course_option");
  }
  get locality() {
    return this.bookGolfForm.get("locality");
  }
  get golf() {
    return this.bookGolfForm.get("golf");
  }
  get booking_date_golf() {
    return this.bookGolfForm.get("booking_date_golf");
  }
  get booking_time() {
    return this.bookGolfForm.get("booking_time");
  }
  get agree_terms() {
    return this.bookGolfForm.get("agree_terms");
  }
  get contact_info() {
    return this.bookGolfForm.get("contact_info");
  }
  get user_type() {
    return this.bookGolfForm.get("user_type");
  }
  get booking_for() {
    return this.bookGolfForm.get("booking_for");
  }
  get guest_name() {
    return this.bookGolfForm.get("guest_name");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  sendPayuRequest(payment_create_response) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);

    for (var key in payment_create_response.payu_body) {
      if (payment_create_response.payu_body.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute(
          "value",
          payment_create_response.payu_body[key]
        );
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  send_payu_golf_request(enquiry) {
    var data = enquiry.user_id;
    this._commonService.makeGolfPayment(data).subscribe((response) => {
      if (response) {
        this.sendPayuRequest(response);
      }
    });
  }

  onChangeCourse(golfData) {
    this.selectedCourse.name = golfData.attributes.gc_name;
    this.selectedCourse.id = golfData.attributes.external_id;
  }
  bookGolf() {
    this.golfError = "";
    this.formSubmited = true;
    if (this.bookGolfForm.valid) {
      this.makingEnquiry = true;
      const golfCources = [
        "AKDR Golf Village - Chennai",
        "Boulder Hills Golf Club - Hyderabad",
        "Madras Gymkhana Club -Chennai",
        "Kharghar Golf Club - Mumbai",
        "Poona Golf Club - Puna",
        "Zion Hills (Champions Reef) - Bengaluru",
        "Jaypee Greens Golf Club - Noida",
        "Jaypee Wishtown Golf Club - Noida",
      ];
      if (
        golfCources.includes(this.bookGolfForm.value.golf_course_option) &&
        this.bookGolfForm.value.booking_date_golf.format("YYYY-MM-DD") <=
          "2020-03-31"
      ) {
        this.golfError =
          "Selected Golf Course is not operational till 31st March, 2020";
        this.toastr.warning(this.golfError);
        return;
      }
      const data = {
        email: this.userData.email,
        golf_course: this.selectedCourse.name,
        golf_course_id: this.selectedCourse.id,
        first_name:
          this.bookGolfForm.value.first_name ??
          this.bookGolfForm.controls.first_name.value,
        last_name:
          this.bookGolfForm.value.last_name ??
          this.bookGolfForm.controls.last_name.value,
        mobile_number: this.userData.mobile,
        booking_type: "learn",
        booking_date_time: _moment(
          this.bookGolfForm.value.booking_date_golf.format("DD/MM/YYYY") +
            " " +
            this.bookGolfForm.value.booking_time,
          "DD/MM/YYYY HH:mm a"
        ).format("ddd, D MMM YYYY h:mm:ss a"),
        bin_used: this.bookGolfForm.value.golf_enquiry_bin,
        user_type: this.bookGolfForm.value.user_type,
        booking_for: this.bookGolfForm.value.booking_for,
        guest_name: this.bookGolfForm.value.guest_name,
      };
      // const formData: FormData = new FormData();
      // formData.append('user_type', this.bookGolfForm.value.user_type);
      // formData.append('booking_for', this.bookGolfForm.value.booking_for);
      // formData.append('guest_name', this.bookGolfForm.value.first_name);
      // formData.append('option1', this.bookGolfForm.value.golf_course_option);
      // formData.append('booking_date1', this.bookGolfForm.value.booking_date_golf.format("YYYY-MM-DD"));
      // formData.append('booking_time1', this.bookGolfForm.value.booking_time);
      // formData.append('bin_used', this.bookGolfForm.value.golf_enquiry_bin);

      this._commonService.golfUpdate(data).subscribe(
        (response) => {
          this.dialogRef.close();
          this.makingEnquiry = false;
          this.router.navigate([
            "golf",
            response.response.enquiry.data.id,
            "checkout",
          ]);
        },
        (err) => {
          this.golfError = err.json().message;
          this.makingEnquiry = false;
          if (this.golfError == "") {
            this.golfError =
              "Something went wrong! Please try again after sometime.";
          }
          this.toastr.warning(this.golfError);
        }
      );
    } else {
      for (const key of Object.keys(this.bookGolfForm.controls)) {
        if (this.bookGolfForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
    }
  }
}

@Component({
  selector: "dialog-bookgolf-familiar",
  templateUrl: "./dialog-bookgolf-familiar.html",
  styleUrls: ["./golf.component.scss"],
  providers: [
    CommonService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    GlobalConstants
  ],
})
export class DialogBookgolfFamiliar {
  golf_experience = false;
  showGolfFields = true;
  invalidBin = false;
  resErrorMessage = "";
  showError = false;
  emailPattern: any;
  mobilePattern: any;
  bookGolfFormFamiliar: any;
  golfUpdate: any;
  currentUser: any;
  userData: any;
  golfError: string = "";
  binDataLoading: any = false;
  makingEnquiry: boolean = false;

  dateFilterFam = (date: Date): boolean => {
    let dateNow = _moment().add(this.globalConstants.GOLF_BOOKING_ALLOWED_BEFORE_DAYS, "days");
    return _moment(date) > dateNow;
  };

  selectedCourse: any = {
    name: "",
    id: "",
  };
  golfCourses: any;
  fetchingGolfCourses: boolean;
  playWithMember = false;
  formSubmited: any = false;
  @Output() passGolfCoursesToParent = new EventEmitter();
  playingMember(i) {
    this.playWithMember = i == true ? true : false;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogBookgolfFamiliar>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private el: ElementRef,
    public globalConstants: GlobalConstants
  ) {
    this.emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.mobilePattern = /^\d{10}$/;

    this.bookGolfFormFamiliar = this.fb.group({
      golf_enquiry_bin: ["", [Validators.required]],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      mobile_number: [
        "",
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      golf_course_option: ["", Validators.required],
      booking_date_golf: ["", Validators.required],
      booking_time: ["", Validators.required],
      golf_enquiry_club_member_name: [""],
      with_club_member: [false, Validators.required],
      agree_terms: ["", Validators.required],
      contact_info: ["", Validators.required],
    });
    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
      // this._commonService.getUserData().subscribe(response =>{
      this.userData = this.currentUser;
      if (this.userData.full_name) {
        let nameArr = this.userData.full_name.split(" ");
        if (nameArr[0]) {
          this.bookGolfFormFamiliar.controls.first_name.setValue(nameArr[0]);
          this.bookGolfFormFamiliar.controls.first_name.disable();
        }
        if (nameArr && nameArr.length > 1) {
          this.bookGolfFormFamiliar.controls.last_name.setValue(
            nameArr[nameArr.length - 1]
          );
          this.bookGolfFormFamiliar.controls.last_name.disable();
        }
      }
      this.bookGolfFormFamiliar.controls["mobile_number"].setValue(
        this.userData.mobile
      );
      this.bookGolfFormFamiliar.controls["email"].setValue(this.userData.email);
      this.bookGolfFormFamiliar.controls["golf_enquiry_bin"].setValue(
        this.userData.bin
      );
      // this.cardbinValidate(this.userData.bin);
      // })
    }
  }

  get golf_enquiry_bin() {
    return this.bookGolfFormFamiliar.get("golf_enquiry_bin");
  }
  get first_name() {
    return this.bookGolfFormFamiliar.get("first_name");
  }
  get last_name() {
    return this.bookGolfFormFamiliar.get("last_name");
  }
  get mobile_number() {
    return this.bookGolfFormFamiliar.get("mobile_number");
  }
  get email() {
    return this.bookGolfFormFamiliar.get("email");
  }
  get golf_course_option() {
    return this.bookGolfFormFamiliar.get("golf_course_option");
  }
  get locality() {
    return this.bookGolfFormFamiliar.get("locality");
  }
  get golf() {
    return this.bookGolfFormFamiliar.get("golf");
  }
  get booking_date_golf() {
    return this.bookGolfFormFamiliar.get("booking_date_golf");
  }
  get booking_time() {
    return this.bookGolfFormFamiliar.get("booking_time");
  }
  get agree_terms() {
    return this.bookGolfFormFamiliar.get("agree_terms");
  }
  get contact_info() {
    return this.bookGolfFormFamiliar.get("contact_info");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  onChangeCourse(golfData) {
    this.selectedCourse.name = golfData.attributes.gc_name;
    this.selectedCourse.id = golfData.attributes.external_id;
  }

  ngAfterViewInit() {
    if (
      Array.isArray(this.data.golfCoursesForFamiliarUsers) &&
      this.data.golfCoursesForFamiliarUsers.length > 0
    ) {
      this.golfCourses = this.data.golfCoursesForFamiliarUsers;
    } else {
      try {
        this.fetchingGolfCourses = true;
        this._commonService.getGolfCourses("play").then((response) => {
          if (
            response.data &&
            response.data.data &&
            Array.isArray(response.data.data) &&
            response.data.data.length > 0
          ) {
            this.golfCourses = response.data.data;
            this.data.passGolfCoursesToParent(response.data.data, true);
          } else {
            this.toastr.error("There was an issue while fetching golf courses");
            this.dialogRef.close();
          }
          this.fetchingGolfCourses = false;
        });
      } catch (error) {
        this.fetchingGolfCourses = false;
        this.toastr.error("There was an issue while fetching golf courses");
        this.dialogRef.close();
      }
    }
  }

  sendPayuRequest(payment_create_response) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);

    for (var key in payment_create_response.payu_body) {
      if (payment_create_response.payu_body.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute(
          "value",
          payment_create_response.payu_body[key]
        );
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  send_payu_golf_request(enquiry) {
    var data = enquiry.user_id;
    this._commonService.makeGolfPayment(data).subscribe((response) => {
      if (response) {
        this.sendPayuRequest(response);
      }
    });
  }

  bookGolfFamiliar() {
    this.formSubmited = true;
    this.golfError = "";
    if (this.bookGolfFormFamiliar.valid) {
      this.makingEnquiry = true;
      const golfCources = [
        "Boulder Hills Golf Club - Hyderabad",
        "Madras Gymkhana Club -Chennai",
        "Kharghar Golf Club - Mumbai",
        "Poona Golf Club - Puna",
        "Zion Hills (Champions Reef) - Bengaluru",
        "Jaypee Greens Golf Club - Noida",
        "Jaypee Wishtown Golf Club - Noida",
      ];
      if (
        golfCources.includes(
          this.bookGolfFormFamiliar.value.golf_course_option
        ) &&
        this.bookGolfFormFamiliar.value.booking_date_golf.format(
          "YYYY-MM-DD"
        ) <= "2020-03-31"
      ) {
        this.golfError =
          "Selected Golf Course is not operational till 31st March, 2020";
        this.toastr.warning(this.golfError);
        return;
      }
      const data = {
        email: this.bookGolfFormFamiliar.value.email,
        golf_course: this.selectedCourse.name,
        golf_course_id: this.selectedCourse.id,
        first_name:
          this.bookGolfFormFamiliar.value.first_name ??
          this.bookGolfFormFamiliar.controls.first_name.value,
        last_name:
          this.bookGolfFormFamiliar.value.last_name ??
          this.bookGolfFormFamiliar.controls.last_name.value,
        mobile_number: this.userData.mobile,
        booking_type: "play",
        booking_date_time: _moment(
          this.bookGolfFormFamiliar.value.booking_date_golf.format(
            "DD/MM/YYYY"
          ) +
            " " +
            this.bookGolfFormFamiliar.value.booking_time,
          "DD/MM/YYYY HH:mm a"
        ).format("ddd, D MMM YYYY h:mm:ss a"),
        club_member_name:
          this.bookGolfFormFamiliar.value.golf_enquiry_club_member_name,
        guest_name: this.bookGolfFormFamiliar.value.guest_name,
        bin_used: this.bookGolfFormFamiliar.value.golf_enquiry_bin,
      };
      // let formData1: FormData = new FormData();
      // formData1.append('option1', this.bookGolfFormFamiliar.value.golf_course_option);
      // formData1.append('booking_date1', this.bookGolfFormFamiliar.value.booking_date_golf.format("YYYY-MM-DD"));
      // formData1.append('booking_time1', this.bookGolfFormFamiliar.value.booking_time);
      // formData1.append('with_club_member1', this.bookGolfFormFamiliar.value.with_club_member);
      // formData1.append('club_member_name1', this.bookGolfFormFamiliar.value.golf_enquiry_club_member_name);
      // formData1.append('guest_name', this.bookGolfFormFamiliar.value.first_name);
      // formData1.append('bin_used', this.bookGolfFormFamiliar.value.golf_enquiry_bin);

      this._commonService.golfUpdate(data).subscribe(
        (response) => {
          if (
            response.response &&
            response.response.enquiry &&
            response.response.enquiry.data &&
            response.response.enquiry.data.id
          ) {
            this.makingEnquiry = false;
            this.dialogRef.close();
            this.router.navigate([
              "golf",
              response.response.enquiry.data.id,
              "checkout",
            ]);
          } else {
            this.toastr.warning(
              "Something went wrong, please try again after sometime"
            );
          }
        },
        (err) => {
          this.golfError = err.json().message;
          if (this.golfError == "") {
            this.golfError =
              "Something went wrong! Please try again after sometime.";
          }
          this.toastr.warning(this.golfError);
          this.makingEnquiry = false;
        }
      );
    } else {
      for (const key of Object.keys(this.bookGolfFormFamiliar.controls)) {
        if (this.bookGolfFormFamiliar.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
    }
  }
}
