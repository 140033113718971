import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonService } from "../services/common-services";
import { DataSharingService } from "../services/data-sharing.service";
import { Loader } from "../services/loader";
import * as moment from "moment";

@Component({
  selector: "app-profile-menu",
  templateUrl: "./profile-menu.component.html",
  styleUrls: ["./profile-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileMenuComponent implements OnInit {
  savedCards: any[];
  currentUser: any;
  paramLink: any;
  moment = moment;

  constructor(
    private _commonService: CommonService,
    private loader: Loader,
    public data: DataSharingService
  ) {}

  ngOnInit() {
    this.loader.userPresent.subscribe((status) => {
      if (status) {
        var user = this._commonService.getUser();
        if (user) {
          this.currentUser = JSON.parse(user);
        }
      }
    });
    this._commonService.getUserDetails().subscribe((response) => {
      if (response) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser")) || {};

        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            ...currentUser,
            ...response,
          })
        );
        this.currentUser = {
          ...currentUser,
          ...response,
        }
      }
    });
    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
    }
    this.getUserCards();
  }

  getUserCards() {
    this._commonService.getUserCards().subscribe(
      (response) => {
        this.savedCards = response.data;
      },
      (err) => {
        console.log(err.status);
        if (err.status == 401) {
          this.data.headerAuthenticated.emit(true);
          this.loader.userLoggedIn(false);
          localStorage.removeItem("access-token");
          localStorage.removeItem("client");
          localStorage.removeItem("uid");
          localStorage.removeItem("currentUser");
          this.currentUser = null;
        }
      }
    );
  }

  logout() {
    this._commonService.logout().then(() => {
      localStorage.removeItem("access-token");
      localStorage.removeItem("client");
      localStorage.removeItem("uid");
      localStorage.removeItem("currentUser");
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    });
  }
}
