import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FormatTimePipe } from './services/formatTime';
import {SafeHtmlPipe} from "./services/safeHtml";
import { PaginationComponent } from './pagination/pagination.component';
import { FooterComponent } from './footer/footer/footer.component';
// import { AuthenticationComponent } from './authentication/authentication.component';
import { HeaderComponent } from './header/header/header.component';
import { FilterMultiplePipe } from './services/filterMultiple.pipe';
import { DebounceClickDirective } from './services/debounce-service';
import { FlightBookingsComponent } from './flight-bookings/flight-bookings.component';
import { MinuteSecondsPipe } from './services/minute-seconds.pipe';
import { ToastrModule } from "ngx-toastr";
import { CreditCardMaskPipe } from './services/credit-card-mask.pipe';
import { MatLegacyDialogModule as MatDialogModule, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { OutsideClickedDirective } from './services/outside-clicked.directive';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    NgbModule,
    NgbCarouselModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  declarations: [FooterComponent,HeaderComponent, FormatTimePipe, PaginationComponent, SafeHtmlPipe, FilterMultiplePipe, DebounceClickDirective, MinuteSecondsPipe, CreditCardMaskPipe, ProfileMenuComponent, OutsideClickedDirective, OutsideClickedDirective],
  exports: [FooterComponent, HeaderComponent, FormatTimePipe, PaginationComponent, SafeHtmlPipe, FilterMultiplePipe, DebounceClickDirective, MinuteSecondsPipe, ToastrModule, CreditCardMaskPipe, MatDialogModule, OutsideClickedDirective, NgbModule, NgbCarouselModule],
})
export class CommonModuleModule { }
