import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get(endpoint, params?) {
    return this.http.get(endpoint, params);
  }

  post(endpoint, body, params?) {
    return this.http.post(endpoint, body, params);
  }

  put(endpoint, body, params?) {
    return this.http.put(endpoint, body, params);
  }

  patch(endpoint, body, params?) {
    return this.http.patch(endpoint, body, params);
  }

  delete(endpoint, params?) {
    return this.http.delete(endpoint, params);
  }

  getUser(userId) {
    return this.http.get(environment.corePath + "/users/" + userId, {});
  }
  getUserDetails() {
    return this.http.get(environment.corePath + "/user", {});
  }

  getAllCountries() {
    return this.http.get(environment.corePath + "/countries");
  }
  getApplicableCountries() {
    return this.http.get(environment.offerPath + "/countries");
  }

  getAllCities(params) {
    return this.http.get(environment.corePath + "/cities", params);
  }

  getAllLocalities(params) {
    return this.http.get(environment.corePath + "/localities", params);
  }

  getCards() {
    return this.http.get(environment.corePath + "/user/user_cards", {});
  }

  addCardAllParams(data) {
    return this.http.post(environment.corePath + "/user/user_cards", data);
  }

  addCard(bin, bin_type) {
    return this.http.post(environment.corePath + "/user/user_cards", {
      bin: bin,
      bin_type: bin_type,
    });
  }

  binValidation(bin) {
    return this.http.get(
      environment.offerPath + "/bins/card_details?bin=" + bin
    );
  }

  validateCard(bin, bin_type) {
    return this.http.get(
      environment.corePath +
        "/user/user_cards/search?bin=" +
        bin +
        "&bin_type=" +
        bin_type
    );
  }

  //get user redemption history
  getRedemptions(params = {}) {
    return this.http.get(environment.offerPath + "/redemptions?", params);
  }

  getClient() {
    return this.http.get(environment.corePath + "/client");
  }

  getOffers(params) {
    return this.http.get(environment.offerPath + "/offers?elgble=true", params);
  }
  getGiftCards(id) {
    return this.http.get(
      environment.offerPath + "/gift_cards/" + id + "?elgble=true",
      {}
    );
  }

  getCategories(params) {
    return this.http.get(
      environment.offerPath + "/categories?elgble=true",
      params
    );
  }

  getCategoryDetails(params = {}) {
    return this.http.get(
      environment.offerPath + "/category_details?elgble=true",
      {
        params,
      }
    );
  }

  getCategoryDetail(id, params) {
    return this.http.get(
      environment.offerPath + "/category_details/" + id + "?elgble=true",
      params
    );
  }

  getMerchants(params) {
    // params['per_page'] = 100;
    return this.http.get(
      environment.offerPath + "/merchants?elgble=true",
      params
    );
  }

  getNearByMerchants(params) {
    // params['per_page'] = 100;
    return this.http.get(
      environment.offerPath + "/nearby_offers/merchants?elgble=true",
      params
    );
  }

  getNearByCategories(params?: any) {
    let data = params || {};
    // params['per_page'] = 30;
    return this.http.get(
      environment.offerPath + "/nearby_offers/categories?elgble=true",
      {
        params: data,
      }
    );
  }

  getNearbyOffers(params) {
    // params['per_page'] = 30;
    return this.http.get(
      environment.offerPath + "/nearby_offers?elgble=true",
      params
    );
  }

  getAllProviders() {
    return this.http.get(environment.offerPath + "/providers?elgble=true", {});
  }

  setIntegrationMode(params) {
    return this.http.put(environment.corePath + "/client/", {
      integration_mode: params["integration_mode"],
    });
  }

  setTemplateId(params) {
    return this.http.put(environment.corePath + "/client/", {
      mobile_template_id: params["mobile_template_id"],
    });
  }

  createMerchant(params) {
    return this.http.post(environment.offerPath + "/merchants", {
      name: params["name"],
      title: params["title"],
      logo: params["logo"],
      description: params["description"],
    });
  }

  createUser(mobile, email) {
    return this.http.post(
      environment.offerPath + "/merchants/create_with_user",
      {
        user: {
          mobile: mobile,
          email: email,
        },
      }
    );
  }

  redeem(offer, params) {
    return this.http.post(
      environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true",
      params
    );
  }
  redeemGiftcard(id, params) {
    return this.http.post(environment.offerPath + "/gift_cards/redeem", params);
  }
  redeemFromCart() {
    return this.http.post(environment.offerPath + "/gift_cards/redeem", null);
  }
  redeemByBin(offer, bin) {
    return this.http.post(
      environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true",
      { bin: bin }
    );
  }
  resendVoucher(offer) {
    return this.http.get(
      environment.offerPath + "/offers/" + offer.id + "/resend_voucher",
      {}
    );
  }

  createTokenUser(token, uid?, client?) {
    let params = {};
    if (token) params["token"] = token;
    if (uid) params["uid"] = uid;
    if (client) params["client"] = client;
    return this.http.post(
      environment.corePath + "/sessions/create_with_user",
      params
    );
  }

  createOffer(params) {
    return this.http.post(environment.offerPath + "/offers", {
      merchant_id: params["merchant_id"],
      name: params["name"],
      description: params["description"],
      terms: params["terms"],
      redemption_steps: params["redemption_steps"],
      vouchers_file: params["vouchers_file"],
      offer_type: params["offer_type"],
    });
  }

  setOfferStatus(params) {
    return this.http.put(environment.offerPath + "/offers/" + params["id"], {
      status: "selected",
    });
  }

  getBundles() {
    return this.http.get(environment.offerPath + "/bundles?elgble=true");
  }

  getBundlesGroups() {
    return this.http.get(environment.offerPath + "/bundle_groups?elgble=true");
  }
  getClientBundles() {
    return this.http.get(environment.offerPath + "/bundles");
  }

  getBundle(id) {
    return this.http.get(
      environment.offerPath + "/bundle_groups/" + id + "?elgble=true"
    );
  }
  getBundleMerchant(id) {
    return this.http.get(environment.offerPath + "/bundles/" + id);
  }
  getIpCountry() {
    return this.http.get(environment.corePath + "/ipdetails");
  }
  checkCardEligibility(bin, bin_type, bundle_id) {
    return this.http.get(
      environment.offerPath +
        "/bins/validate?bin=" +
        bin +
        "&bundle_id=" +
        bundle_id
    );
  }

  //giftcard cart
  getGiftcardsInCart() {
    return this.http.get(environment.corePath + `/user/cart/cart_items`);
  }
  deleteFromCart(deleteId) {
    return this.http.delete(
      environment.corePath + `/user/cart/cart_items/${deleteId}`,
      {}
    );
  }
  pushToCart(cartParams) {
    return this.http.post(
      environment.corePath + "/user/cart/add_items",
      cartParams
    );
  }
}
