<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-8 col-sm-9 foot-left pr-0">
        <div class="footer-links">
          <ul>
            <li><a routerLink="/info/terms-and-conditions" target="_blank">Terms &amp; Conditions</a></li>
            <li><a routerLink="/info/cancellation-policy" target="_blank">Cancellation Policy</a></li>
            <li><a routerLink="/info/privacy-policy" target="_blank">Privacy Policy</a></li>

            <li><a routerLink="/info/contact-us" class="contact-link" target="_blank">Contact Us</a></li>
            <!-- <li><a href="/booking_cancellation/edit">Booking Cancellation</a></li> -->

          </ul>
        </div>
        <div class="copyright">
          <p>© Axis Bank, 2020-21</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-3 foot-right pa-0 pl-0">
        <!-- <div class="row text-right powered-by">
          <p>Powered by</p>
          <a href="http://poshvine.com" target="blank"><img class="img-fluid pull-right poshvine-logo" src="../..//assets/poshvine-logo.png" alt="Poshvine"></a>
        </div> -->
        <div class="powered-icons">
          <a href="http://poshvine.com" target="blank"><img src="../../assets/powered-by.png" alt="powered by poshvine"
              class="posh-logo"></a>
          <img src="../../assets/PCI-DSS-PoshVine.png" alt="PoshVine-PCI-DSS" class="pci-logo">
        </div>
      </div>
    </div>
  </div>
</footer>