import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { User } from './user.modal';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public isAuthenticationComponentOpen: boolean = false;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.http.get<any>(environment.corePath + '/user' )
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
  }

  login(email: string, password: string) {
    return this.http.post<any>(environment.corePath + '/sessions', { email, password })
    .pipe(
        map(res => {
          var user = {
            subdomain: res.data.relationships.target.links.subdomain,
            token: res.data.attributes.token
          }
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);

            // switch (this.GlobalConstants.data.loginAt) {
            //   case "otp":
            //     localStorage.setItem('currentUser', JSON.stringify(user));
            //     this.currentUserSubject.next(res);
            //     break;
            //   case "card":
            //     sessionStorage.setItem('currentUser', JSON.stringify(user));
            //     this.currentUserSubject.next(res);
            //   default:
            //     localStorage.setItem('currentUser', JSON.stringify(user));
            //     this.currentUserSubject.next(res);
            //     break;
            // }
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateExistingUser(mobile: string, bin = '') {
    if(bin ==''){
      return this.http.get<any>(environment.corePath + '/users/search?mobile=' + mobile )
      .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
    else{
      return this.http.get<any>(environment.corePath + '/users/search?mobile=' + mobile+ '&bin=' + bin )
      .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
  }

  createUser(mobile: string, email: string, country_id:string = "default") {
    if(country_id == 'default')
    {
      return this.http.post<any>(environment.corePath + '/users', {mobile: mobile, email: email} )
      .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
    else{

        return this.http.post<any>(environment.corePath + '/users', {mobile: mobile, email: email, country_id: country_id} )
        .pipe(
            map(res => {
              return res;
            }),
            catchError((err: HttpErrorResponse) => {
              return throwError(err);
            })
          );
    }

  }

  requestUserOtp(mobile: string, userId: string){
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps', {mobile : mobile, otp_type: "login"})
    .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateUserOtp(userId: string, otp: string, otpType: string, mobileNum?: any) {
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps/verify', {otp_type : otpType, code: otp})
    .pipe(
        map(res => {
          var user = {
            id: userId,
            token: res.token
          }
          if(mobileNum){
            user['mobile'] = mobileNum;
          }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  authenticateConcierge(password: string){
    return this.http.post<any>(environment.corePath + '/sessions/concierge_login', {password: password})
    .pipe(
      map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
    );
  }

  validateExistingUserThroughConcierge(mobile: string, password: string) {
    return this.http.get<any>(environment.corePath + '/users/search?mobile=' + mobile + '&password=' + password )
    .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  externalRequestUserOtp(mobile: string){
    return this.http.post<any>(environment.corePath + '/external_otps', {mobile : mobile})
    .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  externalValidateUserOtp(otp: string, ref_no: string, mobileNum?: any) {
    return this.http.post<any>(environment.corePath + '/external_otps/verify', {ref_no : ref_no, code: otp})
    .pipe(
        map(res => {
          var user = {
            id: res.id,
            token: res.token
          }
          if(mobileNum){
            user['mobile'] = mobileNum;
          }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  deleteToken(){
    if(JSON.parse(localStorage.getItem('currentUser'))){
      var currentUserId = JSON.parse(localStorage.getItem('currentUser')).id
    }
    return this.http.post<any>(environment.corePath + '/sessions/' + currentUserId, {})
    .pipe(
      map(res => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }
}
