import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../common/services/common-services";
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { User } from "../../common/services/user";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";

declare var $: any;
declare var jquery: any;
@Component({
  selector: "app-golf-checkout",
  templateUrl: "./golf-checkout.component.html",
  styleUrls: ["./golf-checkout.component.scss"],
  providers: [CommonService, DatePipe],
})
export class GolfCheckoutComponent implements OnInit {
  enquiryId: any;
  bookingDate: any;
  bookingTime: any;
  enquiryData: any;
  userData: any;
  backLink: any;
  currentUser: any;
  cardInvalid: boolean = false;
  bookingId: any;

  constructor(
    private user: User,
    private _commonService: CommonService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    public meta: Meta,
    public pageTitle: Title,
    private router: Router
  ) {
    // var userData = this._commonService.getUser();
    // if(userData){
    //   this._commonService.getUserData().subscribe(response =>{
    //     this.userData = response;
    //   },err=>{
    //         this.router.navigate(["golf"]);
    //   })
    // }
    // else {
    //   this.router.navigate(["golf"]);
    // }
  }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, 0);
    $('[data-toggle="tooltip"]').tooltip();

    this.backLink = "/golf";
    this.route.params.subscribe((params) => {
      this.enquiryId = params["enquiry_id"];
      const data = {
        id: this.enquiryId,
      };
      this._commonService.enquiryDetail(data).subscribe((response) => {
        var title =
          "Complete your golf enquiry - Axis Bank Extraordinary Weekends";
        this.pageTitle.setTitle(title);
        this.meta.updateTag({ name: "description", content: title });

        this.bookingId = location.pathname.split("/")[2];

        this.enquiryData = {
          golf_course: response.data.attributes.request_info
            ? response.data.attributes.request_info.golf_course
            : "",
          booking_date: response.data.attributes.booking_date_time,
          booking_time: moment(
            response.data.attributes.booking_date_time
          ).format(),
          club_member_name: response.data.attributes.request_info
            ? response.data.attributes.request_info.club_member_name
            : null,
        };
      });
    });
  }
}
