export const appConstants = {
    cards :[
        { id: 1, name: 'Axis Bank Reserve Credit Card', bin: '43656001' , align : 'vertical', image: '../../../../assets/cards/axis_bank_reserve_card.png'},
        { id: 2, name: 'Axis Bank SELECT Credit Card', bin: '41114606',align : 'horizontal', image: '../../../../assets/cards/select-credit-card-axis.jpg'},
        { id: 3, name: 'Axis Bank Vistara Infinite Credit Card', bin: '43083400', align : 'horizontal',image: '../../../../assets/cards/Vistara-Card_Black-Front_1.5X1-ft.jpg'},
        { id: 4, name: 'Axis Bank Vistara Signature Credit Card', bin: '43083300', align : 'horizontal', image: '../../../../assets/cards/Vistara-Card_Blue-Front_1.5X1-ft.jpg'},
        { id: 5, name: 'Axis Bank Vistara Platinum Credit Card', bin: '43083200',align : 'horizontal', image: '../../../../assets/cards/Vistara-Card_Purple-Front_1.5X1-ft.jpg'},
        { id: 6, name: 'Miles &amp; More Axis Bank World Select Credit Card', bin: '55934000',align : 'horizontal', image: '../../../../assets/cards/miles-more-card.jpg'},
        { id: 6, name: 'Miles &amp; More Axis Bank World Select Credit Card (NFC)', bin: '55934001', align : 'horizontal',image: '../../../../assets/cards/miles-more-card.jpg'},
        { id: 7, name: 'Miles &amp; More Axis Bank World Credit Card', bin: '55934100',align : 'horizontal', image: '../../../../assets/cards/miles-more-s-card.jpg'},
        { id: 8, name: 'Miles &amp; More Axis Bank World Credit Card (NFC)', bin: '55934101',align : 'horizontal', image: '../../../../assets/cards/miles-more-s-card.jpg'},
        { id: 8, name: 'Axis Bank Signature Credit Card', bin: '41114600',align : 'horizontal', image: '../../../../assets/cards/signature-card.png'},
        { id: 8, name: 'Axis Bank Signature Credit Card with Lifestyle Benefits', bin: '41114602', align : 'horizontal',image: '../../../../assets/cards/signature-card.png'},
        { id: 8, name: 'Axis Bank Signature Credit Card with Travel Benefits', bin: '41114603', align : 'horizontal',image: '../../../../assets/cards/signature-card.png'},
        { id: 8, name: 'Axis Bank Signature', bin: '41114601',align : 'horizontal', image: '../../../../assets/cards/signature-card.png'},
        { id: 8, name: 'Axis Bank Advantage Credit Card', bin: '41114604', align : 'horizontal',image: '../../../../assets/cards/signature-card.png'},
        { id: 9, name: 'Axis Bank Privilege Credit Card with Travel Benefits', bin: '55934200', align : 'horizontal',image: '../../../../assets/cards/previlage-card.jpg'},
        { id: 8, name: 'Axis Bank Infinite Card', bin: '43656000',align : 'horizontal', image: '../../../../assets/cards/Vistara-Card_Black-Front_1.5X1-ft.jpg'},
        // { id: 10, name: 'Axis Bank Magnus Credit Card', image: '../../../../assets/cards/axis_bank_magnus_card.png'},
        // { id: 11, name: 'Axis Bank Burgundy Private Credit Card', image: '../../../../assets/cards/axis_burgundy_private_credit_card.JPG'},
        // { id: 12, name: 'Axis Bank Business Supreme Debit Card', image: '../../../../assets/cards/Business_Supreme_Card.jpg'},
        { id: 13, name: 'Axis Bank Burgundy Debit Card', bin: '512932', align : 'horizontal',image: '../../../../assets/cards/Burgundy-Debit-Card-Grey.png'},
        { id: 13, name: 'Axis Bank Burgundy Debit Card', bin: '527114',align : 'horizontal', image: '../../../../assets/cards/Burgundy-Debit-Card-Grey.png'},
        // { id: 14, name: 'Axis Bank Priority Platinum Debit Card', image: '../../../../assets/cards/priority_platinum_debit_card.jpg'},
        // { id: 15, name: 'Axis Bank Delight Debit Card', image: '../../../../assets/cards/axis_bank_delight_card.png'},
        // { id: 16, name: 'Axis Bank Burgundy Private Debit Card', image: '../../../../assets/cards/axis_burgundy_private_debit_card.jpg'},
    ],
    conciergeEmail:'axisbankconcierge@europ-assistance.in'
}
