<app-header class="default-head"></app-header>
<div class="clearfix"></div>
<section class="outer-sec">
  <div class="container main-container">
    <section class="breadcumb_area container hidden-xs noPadding ">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-left">
            <div class="breadcumb_section">
              <div class="page_pagination">
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><i class="fa fa-angle-right text-gray" aria-hidden="true"></i></li>
                  <li><a [href]="backLink">Golf</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="row main-row">
      <div class="col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 pt-3">
        <div class="show payment-container block relative">
          <div class="show block single-box payment-box">
            <div class="flex pd-tb10 ch-lbl">
              <h3>Make Payment</h3>
            </div>
            <div class="checkout-box pl-0 pr-0 mb-0 pt-2" *ngIf="enquiryData">
              <div class="flex">
                <div class="checkout-logo">
                  <img src="../../assets/golf-d.jpg" class="img-fluid">
                </div>
                <div class="checkout-top-right">
                  <div class="chk-heading">{{enquiryData.golf_course}}</div>
                </div>
              </div>
              <div class="chk-iterations">
                <div class="bill-title">Booking Details</div>
    
                <div class="block ch-breakdown">
                  <div class="left">Date</div>
                  <div class="right">{{enquiryData.booking_date | date: 'mediumDate'}}</div>
                </div>
                <div class="block ch-breakdown">
                  <div class="left">Time</div>
                  <div class="right">{{enquiryData.booking_time.split('.')[0] | date: 'shortTime'}}</div>
                </div>
    
                <div class="bill-title block mt15">Bill Details</div>
                <div class="block ch-breakdown">
                  <div class="left">Number of Adults</div>
                  <div class="right">1<span *ngIf="enquiryData.club_member_name"> (+1 Guest)</span></div>
                </div>
                <div class="block ch-breakdown">
                  <div class="left">Total Cost </div>
                  <div class="right"><i class="fa fa-rupee"></i> 10</div>
                </div>
                <div class="block ch-balance">
                  <div class="left">Online Booking Amount</div>
                  <div class="right"><i class="fa fa-rupee"></i> 10</div>
                </div>
              </div>
            </div>
            <app-payment [payingFor]="'golf'" [bookingId]="bookingId"></app-payment>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="clearfix"></div>
<app-footer></app-footer>