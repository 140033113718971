import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { GolfComponent } from "./pages/golf/golf/golf.component";
// import { HotelsCheckoutComponent } from "./pages/hotels/hotels-checkout/hotels-checkout.component";
// import { HotelThankyouComponent } from "./pages/hotels/hotal-thankyou/hotel-thankyou-component";
// import { HotelFailedComponent } from "./pages/hotels/hotels-failed/hotel-failed-component";
import { GolfCheckoutComponent } from "./pages/golf/golf-checkout/golf-checkout.component";
import { AirportVipPrivilegesComponent } from "./pages/airport-vip-privileges/airport-vip-privileges/airport-vip-privileges.component";
import { AirportVipPrivilegesCheckoutComponent } from "./pages/airport-vip-privileges/airport-vip-privileges-checkout/airport-vip-privileges-checkout.component";
import { AirportTransfersComponent } from "./pages/airport-transfers/airport-transfers/airport-transfers.component";
import { AirportTransfersCheckoutComponent } from "./pages/airport-transfers/airport-transfers-checkout/airport-transfers-checkout.component";

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () => import('./pages/homepage/homepage/homepage.module').then(m => m.HomepageModule),
  },
  {
    path: "home",
    loadChildren: () => import('./pages/homepage/homepage/homepage.module').then(m => m.HomepageModule),
  },
  { path: "golf", component: GolfComponent },
  { path: "info", loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule) },
  { path: "golf/:enquiry_id/checkout", component: GolfCheckoutComponent },
  {
    path: "settings",
    loadChildren: () => import('./pages/common/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: "profile",
    loadChildren: () => import('./pages/common/profile/profile.module').then(m => m.ProfileModule),
  },
  { path: "404", loadChildren: () => import('./pages/common/404/404.module').then(m => m.E404Module) },
  {
    path: "maintenance",
    loadChildren:
      () => import('./pages/common/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: "noresult",
    loadChildren: () => import('./pages/common/noresult/noresult.module').then(m => m.NoresultModule),
  },
  {
    path: "golf-bookings",
    loadChildren:
      () => import('./pages/common/golf-booking/golf-booking.module').then(m => m.GolfBookingModule),
  },
  {
    path: "golf-enquiry/checkout-message/:bookingID",
    redirectTo: "/golf-bookings",
  },
  {
    path: "airport-transfer-bookings",
    loadChildren:
      () => import('./pages/common/airport-transfer-booking/airport-transfer-booking.module').then(m => m.AirportTransferBookingModule),
  },
  {
    path: "airport_transfer/checkout-message/:bookingID",
    redirectTo: "/airport-transfer-bookings",
  },
  {
    path: "airport-concierge-service",
    component: AirportVipPrivilegesComponent,
  },
  { path: "airport-transfers", component: AirportTransfersComponent },
  {
    path: "airport-transfers/:enquiry_id/checkout",
    component: AirportTransfersCheckoutComponent,
  },
  {
    path: "airport-vip-privileges/:enquiry_id/checkout",
    component: AirportVipPrivilegesCheckoutComponent,
  },
  {
    path: "airport-concierge-service-bookings",
    loadChildren:
      () => import('./pages/common/flight-bookings/flight-bookings.module').then(m => m.FlightBookingsModule),
  },
  {
    path: "airport_vip_privilege/checkout-message/:bookingID",
    redirectTo: "/airport-concierge-service-bookings",
  },
  {
    path: "flight-bookings",
    loadChildren:
      () => import('./pages/common/flight-bookings/flight-bookings.module').then(m => m.FlightBookingsModule),
  },
];

export const routing = RouterModule.forRoot(appRoutes, {});
