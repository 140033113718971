import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';

@Injectable()
export class GlobalConstants {
    client: any;
    data:any;

    public GOLF_BOOKING_ALLOWED_BEFORE_DAYS = 4;
    public AIRPORT_TRANSFER_ALLOWED_BEFORE_DAYS = 1;
    public AIRPORT_CONCIERGE_ALLOWED_BEFORE_DAYS = 1;

    constructor(@Inject(DOCUMENT) private document: any, private router: Router) {
        this.client = "axis-bank";
        this.data = {
            "logo": {
                "url": "https://images.poshvine.com/logos/axis-logo-pink.png",
                "footerurl": "https://images.poshvine.com/logos/axis-logo-pink.png",
                "desktop_url": "https://images.poshvine.com/logos/axis-logo-pink.png",
                "modal_url": "https://images.poshvine.com/logos/axis-logo-pink.png",
                "favicon": "https://extraordinaryweekends.com/favicon.ico",
                "head_logo_height": "40",
                "coupon_modal_logo": "https://images.poshvine.com/logos/axis-logo-white.png"
            },
            "assets": {
                "img": {
                    "banner": [
                        {'img': 'https://www.axisbank.com/images/default-source/revamp_new/grab-deals/banner/big-offers1a1800babe576bf08df9ff0a000b8c1c.jpg', 'id': '','tagline': ''},
                        {'img': "https://images.poshvine.com/b/axis-mob.jpg", 'id': '25'}
                    ],
                },
                "manifest": "manifest.webmanifest"

            },
            "links": {
                "projectLink": "https://axis-bank.poshvine.com/"
            },
            "colors": {
                "header_bg_color": "#fff",
                "coupon_modal_bg_color": "#b13a5e",
                "offer_title_color": '#ae275f',
                "main_text_color": '#740316',
                "medium_text_color": '#5C5C5C',
                "light_text_color": '#9D9D9D',
                "main_bg_color": '#F4F4F4',
                "active_card_bg_color": '#F4F4F4',
                "seconday_bg_color": '#FDFCFA',
                "mainBtn_color": '#97144d',
                "inactive_btn_color": '#D8D8D8',
                "btn_positive_action_color": '#06B08A',
                "active_text_color": '#740316',
            },
            "legal_name" : "Extraordinary Weekends",
            "font_family": {
              "link": "https://fonts.googleapis.com/css?family=Roboto:400,500,700",
              "name": "Roboto"
            },
            "card_num_type": "first6",
            "card_type": "Debit",
            "tabs_type": "category",
            "tabs_type_mobile": "category",
            "has_exclusive": false,
            "banner_type": "static",
            "login_button_needed": true,
            "login_theme": 1,
            "loginAt": "external",
            "auto_expiry": {
                "time" : "15",
                "enabled" : true
            },
            "template" : 1,
            "header_type": "axis_header",
            "footer_type": "axis_footer",
            "show_saving_for_bundle_codes": {},
            "download_app_link": {
                'header': false,
                'footer': false,
                'android_link': null,
                'apple_link': null
            },
            "login_modal_type": "desktop-prev-header",
            "supported_devices": ['web','mobile'],
            "benefits":['offers'],
            "personalized_config": {
                "switch_card": true,
                "autocard_check": false,
                "view_savings": true,
                "external_otp": false,
                "redemption_page_needed": true,
                "saving_page": false,
                "resend_allowed": true,
                "add_new_card": true,
                "footer_logo": false,
                "force_auth": true,
                "token_auth": true,
                "desktop_search": true,
                "mobile_search": true,
                "auth": true,
                "coupon_modal": true,
                "coupon_show": true,
                "amount_show": false,
                "modal_redeem_button": true,
                "mobile_home_back_button": true,
                "show_card_used": false,
                "allow_user_create": true,
                "dedicated_banner": true,
                "mobile_banner": true,
                "authenticate_before_claim": true,
                "disclaimer_popup": true,
                "cancelable_auth": true,
                "display_bundle_category_title": true,
                "mobile_login": true,
                "is_concierge": false,
                "has_card": true,
                "home_subcategory_dropdown": false,
                "home_subcategory_bar": true,
                "has_near_by": false,
                "mobile_profile_dropdown": true,
                "category_page": false,
                "authenticate_bin": true,
                "datalayer": false,
                "has_add_card_text": false,
                "single_step_login": false
            },
            "titles": {
                "homepage_pagetitle": "Offers & Privileges - Extraordinary Weekends",
                "homepage_description": "",
                "homepage_keywords": "",
                "header_title": "",
                "modal_mobile_label": "Enter your mobile number registered with Axis bank",
                "card_name" : "Axis card",
                "platform_title": "Extraordinary Weekends",
                "concierge_name": "Extraordinary Weekends",
                "login_modal_control": "Opt in to receive offers & benefits"
            },
            "footer_additional_content": "<p>Disclaimer: Extraordinary Weekends is a platform for communication of personalized offers extended by Merchants to Axis's Customers. Extraordinary Weekends is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. Extraordinary Weekends is neither guaranteeing nor making any representation. Extraordinary Weekends is not responsible for Sale/Quality/Features of the Products/Services under the offers</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
            "content":{
                "bin_error": {
                    "first4_last4": "Dear Customer, the combination of your mobile number and the first 4 and last 4 digits of your Debit Card number did not get validated. Please try again.",
                    "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                },
                "bin_invalid": {
                    "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                    "first6": "Invalid Card"
                }
            },
            "BINS_TO_REDIRECT_TO_APEX": [],
            "REDIRECTION_LINK" : "https://demoapp.apexlynx.net/"
        };
     }

}
