import {
  Component,
  OnInit,
  NgModule,
  Inject,
  Pipe,
  PipeTransform,
  ElementRef,
} from "@angular/core";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { CommonService } from "../../common/services/common-services";
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
// import { UniquePipe } from '../../common/services/unique';
// import { debug } from 'util';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthenticationComponent } from "./../../common/authentication/authentication.component";
import { DataSharingService } from "./../../common/services/data-sharing.service";

import * as _moment from "moment";
// import moment = require('moment');
import * as moment from "moment";
import { min } from "rxjs/operators";
import { Loader } from "../../common/services/loader";
import { GlobalConstants } from "../../common/services/global-variables";
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

declare var $: any;

export interface DialogData {}

@Component({
  selector: "app-airport-vip-privileges",
  templateUrl: "./airport-vip-privileges.component.html",
  styleUrls: ["./airport-vip-privileges.component.scss"],
  providers: [CommonService],
})
export class AirportVipPrivilegesComponent implements OnInit {
  currentUser: any;
  userData: any;
  cardValidating: boolean = false;
  airportList: any = [
    {
      city: "Ahmedabad",
      airport: "Sardar Vallabhbhai Patel International Airport, T1-Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Ahmedabad",
      airport:
        "Sardar Vallabhbhai Patel International Airport, T2-International",
      buggy_service: "N",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
    // {
    //   city: "Amritsar",
    //   airport: "Sri Guru Ram Dass Jee International Airport, Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {
      city: "Bangalore",
      airport: "Kempegowda Bangalore International Airport, T1-Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Bangalore",
      airport: "Kempegowda Bangalore International Airport, T2-International",
      buggy_service: "N",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Calicut",
      airport: "Calicut International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Coimbatore",
      airport: "Coimbatore International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Guwahati",
      airport: "Lokpriya Gopinath Bordoloi International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    // {city: "Bhubaneshwar", airport: "Biju Patnaik Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {
    //   city: "Chandigarh",
    //   airport: "Chandigarh International Airport, T1-Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    // {
    //   city: "Chandigarh",
    //   airport: "Chandigarh International Airport, T1-International",
    //   buggy_service: "N",
    //   domestic: "N",
    //   international: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {city: "Chennai", airport: "Chennai International Airport, T1-Domestic", buggy_service: "Y", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    {city: "Chennai", airport: "Chennai International Airport, T2-Domestic", buggy_service: "Y", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    {city: "Chennai", airport: "Chennai International Airport, T3-International", buggy_service: "Y", domestic: "N", international: "Y", departure: "Y", arrival: "Y"},
    {city: "Chennai", airport: "Chennai International Airport, T4-International", buggy_service: "Y", domestic: "N", international: "Y", departure: "Y", arrival: "Y"},
    // {
    //   city: "Cochin",
    //   airport: "Cochin International Airport, T3 International",
    //   buggy_service: "N",
    //   domestic: "N",
    //   international: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    // {city: "Dehradun", airport: "Jolly Grant Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Goa", airport: "Dabolim International Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Goa", airport: "Dabolim International Airport, International", buggy_service: "N", domestic: "N", international: "Y", departure: "Y", arrival: "Y"},
    // {
    //   city: "Guwahati",
    //   airport: "Lokpriya Gopinath Bordoloi International Airport, Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {
      city: "Hyderabad",
      airport: "Rajiv Gandhi International Airport, Domestic ",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Hyderabad",
      airport: "Rajiv Gandhi International Airport, International",
      buggy_service: "N",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
    // {city: "Imphal", airport: "Bir Tikendrajit International Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Indore", airport: "Devi Ahilya Bai Holkar International Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    {
      city: "Jaipur",
      airport: "Jaipur International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    // {city: "Jaiselmer", airport: "Jaisalmer Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Jammu", airport: "Satwari Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Jodhpur", airport: "Jodhpur Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Leh", airport: "Kushok Bakula Rimpochee Airport, Domestic", buggy_service: "N", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {
    //   city: "Kolkata",
    //   airport: "Netaji Subhash Chandra Bose International Airport, Domestic",
    //   buggy_service: "Y",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    // {
    //   city: "Kolkata",
    //   airport:
    //     "Netaji Subhash Chandra Bose International Airport, International",
    //   buggy_service: "Y",
    //   domestic: "N",
    //   international: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {
      city: "Kannur",
      airport: "Kannur International Airport",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Kochi",
      airport: "Cochin International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Lucknow",
      airport: "Chaudhary Charan Singh International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Mangalore",
      airport: "Mangalore International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport, T1-Domestic",
      buggy_service: "Y",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport, T2-Domestic",
      buggy_service: "Y",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Mumbai",
      airport:
        "Chhatrapati Shivaji Maharaj International Airport, T2-International",
      buggy_service: "Y",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport, T1-Domestic",
      buggy_service: "Y",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport, T2-Domestic",
      buggy_service: "Y",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport, T3-Domestic",
      buggy_service: "Y",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport, T3-International",
      buggy_service: "Y",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
    // {city: "Nagpur", airport: "Dr. Babasaheb Ambedkar Airport, Domestic", buggy_service: "Y", domestic: "Y", international: "N", departure: "Y", arrival: "Y"},
    // {city: "Nagpur", airport: "Dr. Babasaheb Ambedkar Airport, International", buggy_service: "Y", domestic: "N", international: "Y", departure: "Y", arrival: "Y"},
    // {
    //   city: "Pune",
    //   airport: "Pune International Airport, Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    // {
    //   city: "Pune",
    //   airport: "Pune International Airport, International",
    //   buggy_service: "N",
    //   domestic: "N",
    //   international: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {
      city: "Trivandrum",
      airport: "Trivandrum International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    // {
    //   city: "Udaipur",
    //   airport: "Maharana Pratap Airport, Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    // {
    //   city: "Vadodara",
    //   airport: "Vadodara Airport, Domestic",
    //   buggy_service: "N",
    //   domestic: "Y",
    //   international: "N",
    //   departure: "Y",
    //   arrival: "Y",
    // },
    {
      city: "Varanasi",
      airport: "Lal Bahadur Shastri International Airport, Domestic",
      buggy_service: "N",
      domestic: "Y",
      international: "N",
      departure: "Y",
      arrival: "Y",
    },
    {
      city: "Varanasi",
      airport: "Lal Bahadur Shastri International Airport, International",
      buggy_service: "N",
      domestic: "N",
      international: "Y",
      departure: "Y",
      arrival: "Y",
    },
  ];
  oldAirportList: any = [
    // {
    //   city: "Ahmedabad",
    //   airport: "Sardar Vallabhbhai Patel International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["T1-Domestic", "T2-International"],
    // },
    // {
    //   city: "Amritsar",
    //   airport: "Sri Guru Ram Dass Jee International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Bangalore",
    //   airport: "Kempegowda Bangalore International Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["T1-Domestic", "T1-International"],
    // },
    // {
    //   city: "Bhubaneshwar",
    //   airport: "Biju Patnaik Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Chandigarh",
    //   airport: "Chandigarh International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["T1-Domestic", "T1-International"],
    // },
    // {
    //   city: "Chennai",
    //   airport: "Chennai International Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: [
    //     "T1-Domestic",
    //     "T2-Domestic",
    //     "T3-International",
    //     "T4-International",
    //   ],
    // },
    {
      city: "Cochin",
      airport: "Cochin International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1-Domestic", "T3 International"],
    },
    // {
    //   city: "Dehradun",
    //   airport: "Jolly Grant Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    {
      city: "Delhi",
      airport: "Indira Gandhi International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1, T2, T3-Domestic", "T3-International"],
    },
    // {
    //   city: "GOA",
    //   airport: "Dabolim International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Guwahati",
    //   airport: "Lokpriya Gopinath Bordoloi International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    {
      city: "Hyderabad",
      airport: "Rajiv Gandhi International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic ", "International"],
    },
    // {
    //   city: "Imphal",
    //   airport: "Bir Tikendrajit International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Indore",
    //   airport: "Devi Ahilya Bai Holkar International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Jaipur",
    //   airport: "Jaipur International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["International", ""],
    // },
    // {
    //   city: "Jaiselmer",
    //   airport: "Jaisalmer Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Jammu",
    //   airport: "Satwari Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Jodhpur",
    //   airport: "Jodhpur Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Leh ",
    //   airport: "Kushok Bakula Rimpochee Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Kolkata",
    //   airport: "Netaji Subhash Chandra Bose International Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Lucknow",
    //   airport: "Chaudhary Charan Singh International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Mangalore",
    //   airport: "Mangalore International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1, T2-Domestic", "T2-International"],
    },
    // {
    //   city: "Nagpur",
    //   airport: "Dr. Babasaheb Ambedkar Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Pune",
    //   airport: "Pune International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Trivandrum",
    //   airport: "Trivandrum International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["International", ""],
    // },
    // {
    //   city: "Udaipur",
    //   airport: "Maharana Pratap Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Vadodara",
    //   airport: "Vadodara Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
    // {
    //   city: "Varanasi",
    //   airport: "Lal Bahadur Shastri International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", ""],
    // },
  ];
  airportDisplayList: any = [
    {
      city: "Ahmedabad",
      airport: "Sardar Vallabhbhai Patel International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1-Domestic", "T2-International"],
    },
    // {
    //   city: "Amritsar",
    //   airport: "Sri Guru Ram Dass Jee International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    {
      city: "Bangalore",
      airport: "Kempegowda Bangalore International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "N",
      terminal: ["T1-Domestic", "T2-International"],
    },
    {
      city: "Calicut",
      airport: "Calicut International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    {
      city: "Coimbatore",
      airport: "Coimbatore International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    {
      city: "Guwahati",
      airport: "Lokpriya Gopinath Bordoloi International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    // {
    //   city: "Bhubaneshwar",
    //   airport: "Biju Patnaik Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Chandigarh",
    //   airport: "Chandigarh International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["T1-Domestic", "T1-International"],
    // },
    {
      city: "Chennai",
      airport: "Chennai International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: [
        "T1-Domestic",
        "T2-Domestic",
        "T3-International",
        "T4-International",
      ],
    },

    // {
    //   city: "Dehradun",
    //   airport: "Jolly Grant Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "GOA",
    //   airport: "Dabolim International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Guwahati",
    //   airport: "Lokpriya Gopinath Bordoloi International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    {
      city: "Hyderabad",
      airport: "Rajiv Gandhi International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic ", "International"],
    },
    // {
    //   city: "Imphal",
    //   airport: "Bir Tikendrajit International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Indore",
    //   airport: "Devi Ahilya Bai Holkar International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    {
      city: "Jaipur",
      airport: "Jaipur International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    // {
    //   city: "Jaiselmer",
    //   airport: "Jaisalmer Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Jammu",
    //   airport: "Satwari Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Jodhpur",
    //   airport: "Jodhpur Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Leh ",
    //   airport: "Kushok Bakula Rimpochee Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Kolkata",
    //   airport: "Netaji Subhash Chandra Bose International Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Lucknow",
    //   airport: "Chaudhary Charan Singh International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Mangalore",
    //   airport: "Mangalore International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    {
      city: "Kannur",
      airport: "Kannur International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic", "International"],
    },
    {
      city: "Kochi",
      airport: "Cochin International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1-Domestic", "T3 International"],
    },
    {
      city: "Lucknow",
      airport: "Chaudhary Charan Singh International Airport, Domestic",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    {
      city: "Mangalore",
      airport: "Mangalore International Airport, Domestic",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: ["T1-Domestic, T2-Domestic", "T2-International"],
    },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport",
      buggy_service: "Y",
      departure: "Y",
      arrival: "Y",
      terminal: [
        "T1-Domestic",
        "T2-Domestic",
        "T3-Domestic",
        "T3-International",
      ],
    },
    // {
    //   city: "Nagpur",
    //   airport: "Dr. Babasaheb Ambedkar Airport",
    //   buggy_service: "Y",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    // {
    //   city: "Pune",
    //   airport: "Pune International Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic", "International"],
    // },
    {
      city: "Trivandrum",
      airport: "Trivandrum International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
    // {
    //   city: "Udaipur",
    //   airport: "Maharana Pratap Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    // {
    //   city: "Vadodara",
    //   airport: "Vadodara Airport",
    //   buggy_service: "N",
    //   departure: "Y",
    //   arrival: "Y",
    //   terminal: ["Domestic"],
    // },
    {
      city: "Varanasi",
      airport: "Lal Bahadur Shastri International Airport",
      buggy_service: "N",
      departure: "Y",
      arrival: "Y",
      terminal: ["Domestic"],
    },
  ];

  title =
    "Special Golf Offers on Axis Bank Premium Cards - Extraordinary Weekends";
  resetHeader: boolean = true;
  constructor(
    public dialog: MatDialog,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public dataS: DataSharingService,
    public meta: Meta,
    public pageTitle: Title,
    private loader: Loader
  ) {}

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    var title = "Airport Concierge Service - Axis Bank Extraordinary Weekends";
    var des = "Book Airport Vip Privileges - Axis Bank Extraordinary Weekends";
    this.pageTitle.setTitle(title);
    this.meta.updateTag({ name: "description", content: des });
    // this.airportDisplayList = this.groupBy(this.airportDisplayList, airportDisplay => airportDisplay.city)
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params.code == 1) {
          this.toastr.success(
            "Thank you for the travel details. You will be sent the service confirmation shortly."
          );
        } else if (params.code == 0) {
          this.toastr.warning(
            "Something went wrong! Please try again after sometime."
          );
        }
      });
    });
  }

  scroll(el) {
    let elm = document.getElementById(el);
    elm.scrollIntoView();
    window.scrollBy(0, -110);
  }

  cardbinValidate(bin_number) {
    this.cardValidating = true;
    if (bin_number.length == 8) {
      this._commonService.airportVipPrivilegeBinValidate(bin_number).subscribe(
        (response) => {
          this.cardValidating = false;
          if (response.status != "error") {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              airportList: this.airportList,
              bookings_count: response.bookings_count,
            };
            const dialogRef = this.dialog.open(
              DialogBookairportVipPrivileges,
              dialogConfig
            );
            dialogRef.afterClosed().subscribe((result) => {});
          } else {
            this.toastr.error(response.msg);
            // this.invalidBin = true;
          }
        },
        (err) => {
          this.cardValidating = false;
          this.toastr.warning(
            "An issue occured during validation, please try again later."
          );
          // this.showError = true;
          // this.resErrorMessage = JSON.parse(err._body).bin_used.join(", ")
        }
      );
    }
  }

  bookAirportVipPrivileges(experience): void {
    if (JSON.parse(this._commonService.getUser())) {
      var user = JSON.parse(this._commonService.getUser());
      if (user && user.bin) {
        this.cardbinValidate(user.bin);
      }
    } else {
      this.dataS.headerAuthenticated.emit(true);
      const dialogRef = this.dialog
        .open(AuthenticationComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            var user = JSON.parse(this._commonService.getUser());
            if (user && user.bin) {
              this.cardbinValidate(user.bin);
            }
          }
        });
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}

@Component({
  selector: "dialog-bookairport-vip-privileges",
  templateUrl: "./dialog-bookairport-vip-privileges.html",
  styleUrls: ["./airport-vip-privileges.component.scss"],
  providers: [
    CommonService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    GlobalConstants
  ],
})
export class DialogBookairportVipPrivileges {
  golf_experience = false;
  showAirportVipPrivilegeFields = true;
  showGuestCountvalidation = true;
  invalidBin = false;
  resErrorMessage = "";
  showError = false;
  emailPattern: any;
  mobilePattern: any;
  passportPattern: any;
  bookAirportVipPrivilegesForm: any;
  golfUpdate: any;
  currentUser: any;
  userData: any;
  guest_count_number: any;
  bookAirportVipPrivilegesError: string = "";
  submitButtonSubmitted: boolean = false;
  filetredAirports: any;
  selectedCity: any;
  events: string[] = [];
  filesList: number[] = [1];
  uploadForm: any;
  filesToUpload: Array<File> = [];
  guestList: number[] = [1, 2, 3];
  guestCount: number = 0;
  totalCount: number = 0;
  airportName: any;
  travelType: string[] = [];
  makingEnquiry: boolean = false;
  //fileData: FormData = new FormData();

  airportList: any = [];
  apList: any;


  dateFilter = (date: Date): boolean => {
    let dateNow = _moment().add(this.globalConstants.AIRPORT_CONCIERGE_ALLOWED_BEFORE_DAYS, "days");
    return _moment(date) > dateNow;
  };
  guest_document_error_message: any;
  guest_document_error: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogBookairportVipPrivileges>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private el: ElementRef,
    public globalConstants: GlobalConstants
  ) {
    this.emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.mobilePattern = /^\d{10}$/;
    this.passportPattern = /^[A-Z][0-9]{8}$/;

    this.bookAirportVipPrivilegesForm = this.fb.group({
      airport_vip_privileges_bin: ["", [Validators.required]],
      city: ["", [Validators.required]],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      mobile_number: [
        "",
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      booking_date: ["", Validators.required],
      booking_time_hour: ["", Validators.required],
      booking_time_minute: ["", Validators.required],
      travel_sector: ["", Validators.required],
      assistance_type: ["", Validators.required],
      airport: ["", Validators.required],
      bookingForGuest: ["", Validators.required],
      passport: [""],
      guest_count: [""],
      guest_name_1: [""],
      guest_passport_1: [""],
      guest_name_2: [""],
      guest_passport_2: [""],
      guest_name_3: [""],
      guest_passport_3: [""],
      guest_document: ["", Validators.required],
      agree_terms: ["", Validators.required],
      contact_info: ["", Validators.required],
    });

    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
      // this._commonService.getUserData().subscribe(response =>{
      this.userData = this.currentUser;
      //
      if (this.userData.full_name) {
        let nameArr = this.userData.full_name.split(" ");
        if (nameArr[0]) {
          this.bookAirportVipPrivilegesForm.controls.first_name.setValue(
            nameArr[0]
          );
          this.bookAirportVipPrivilegesForm.controls.first_name.disable();
        }
        if (nameArr && nameArr.length > 1) {
          this.bookAirportVipPrivilegesForm.controls.last_name.setValue(
            nameArr[nameArr.length - 1]
          );
          this.bookAirportVipPrivilegesForm.controls.last_name.disable();
        }
      }
      this.bookAirportVipPrivilegesForm.controls["mobile_number"].setValue(
        this.userData.mobile
      );
      this.bookAirportVipPrivilegesForm.controls["email"].setValue(
        this.userData.email
      );
      this.bookAirportVipPrivilegesForm.controls[
        "airport_vip_privileges_bin"
      ].setValue(this.userData.bin);
      // })
    }
    if (this.data && this.data.airportList) {
      this.airportList = this.data.airportList;
    }
    this.setUserCategoryValidators();
  }

  // get airport_vip_privileges_bin() { return this.bookAirportVipPrivilegesForm.get('airport_vip_privileges_bin') };
  get city() {
    return this.bookAirportVipPrivilegesForm.get("city");
  }
  get first_name() {
    return this.bookAirportVipPrivilegesForm.get("first_name");
  }
  get last_name() {
    return this.bookAirportVipPrivilegesForm.get("last_name");
  }
  get mobile_number() {
    return this.bookAirportVipPrivilegesForm.get("mobile_number");
  }
  get email() {
    return this.bookAirportVipPrivilegesForm.get("email");
  }
  get booking_date() {
    return this.bookAirportVipPrivilegesForm.get("booking_date");
  }
  get booking_time_hour() {
    return this.bookAirportVipPrivilegesForm.get("booking_time_hour");
  }
  get booking_time_minute() {
    return this.bookAirportVipPrivilegesForm.get("booking_time_minute");
  }
  get travel_sector() {
    return this.bookAirportVipPrivilegesForm.get("travel_sector");
  }
  get assistance_type() {
    return this.bookAirportVipPrivilegesForm.get("assistance_type");
  }
  get airport() {
    return this.bookAirportVipPrivilegesForm.get("airport");
  }
  get bookingForGuest() {
    return this.bookAirportVipPrivilegesForm.get("bookingForGuest");
  }
  get guest_count() {
    return this.bookAirportVipPrivilegesForm.get("guest_count");
  }
  get guest_name_1() {
    return this.bookAirportVipPrivilegesForm.get("guest_name_1");
  }
  get guest_name_2() {
    return this.bookAirportVipPrivilegesForm.get("guest_name_2");
  }
  get guest_name_3() {
    return this.bookAirportVipPrivilegesForm.get("guest_name_3");
  }
  get guest_passport_1() {
    return this.bookAirportVipPrivilegesForm.get("guest_passport_1");
  }
  get guest_passport_2() {
    return this.bookAirportVipPrivilegesForm.get("guest_passport_2");
  }
  get guest_passport_3() {
    return this.bookAirportVipPrivilegesForm.get("guest_passport_3");
  }
  get passport() {
    return this.bookAirportVipPrivilegesForm.get("passport");
  }
  get guest_document() {
    return this.bookAirportVipPrivilegesForm.get("guest_document");
  }
  get agree_terms() {
    return this.bookAirportVipPrivilegesForm.get("agree_terms");
  }
  get contact_info() {
    return this.bookAirportVipPrivilegesForm.get("contact_info");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  sendPayuRequest(payment_create_response) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);

    for (var key in payment_create_response.payu_body) {
      if (payment_create_response.payu_body.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute(
          "value",
          payment_create_response.payu_body[key]
        );
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  send_payu_golf_request(enquiry) {
    var data = enquiry.user_id;
    this._commonService
      .makeAirVipPrivilegesPayment(data)
      .subscribe((response) => {
        if (response) {
          this.sendPayuRequest(response);
        }
      });
  }

  set_guest_count(num) {
    const travel_sector = this.bookAirportVipPrivilegesForm.value.travel_sector;
    const guest_name_1 = this.bookAirportVipPrivilegesForm.get("guest_name_1");
    const guest_name_2 = this.bookAirportVipPrivilegesForm.get("guest_name_2");
    const guest_name_3 = this.bookAirportVipPrivilegesForm.get("guest_name_3");
    const guest_passport_1 =
      this.bookAirportVipPrivilegesForm.get("guest_passport_1");
    const guest_passport_2 =
      this.bookAirportVipPrivilegesForm.get("guest_passport_2");
    const guest_passport_3 =
      this.bookAirportVipPrivilegesForm.get("guest_passport_3");
    if (num == 1) {
      this.guest_count.value = num;
      guest_name_1.setValidators([Validators.required]);
      this.guest_count.value = num;
      if (
        this.bookAirportVipPrivilegesForm.value.travel_sector == "International"
      ) {
        guest_passport_1.setValidators([Validators.required]);
      }
    } else {
      guest_name_1.clearValidators();
      guest_name_1.updateValueAndValidity();
      guest_passport_1.clearValidators();
      guest_passport_1.updateValueAndValidity();
      guest_name_2.clearValidators();
      guest_name_2.updateValueAndValidity();
      guest_passport_2.clearValidators();
      guest_passport_2.updateValueAndValidity();
      guest_name_3.clearValidators();
      guest_name_3.updateValueAndValidity();
      guest_passport_3.clearValidators();
      guest_passport_3.updateValueAndValidity();
    }
    this.guest_count_number = num;
    this.filesList.push(num);
    if (num == 0) {
      this.filesList = [1];
      this.bookAirportVipPrivilegesForm.get("guest_count").value = 0;
    }
  }

  change_guest_count() {
    let guest_num = this.guest_count.value;
    const travel_sector = this.bookAirportVipPrivilegesForm.value.travel_sector;
    if (
      this.bookAirportVipPrivilegesForm &&
      this.bookAirportVipPrivilegesForm.value &&
      this.bookAirportVipPrivilegesForm.value.guest_count
    ) {
      this.guest_count_number =
        this.bookAirportVipPrivilegesForm.value.guest_count;
      const guest_name_1 =
        this.bookAirportVipPrivilegesForm.get("guest_name_1");
      const guest_name_2 =
        this.bookAirportVipPrivilegesForm.get("guest_name_2");
      const guest_name_3 =
        this.bookAirportVipPrivilegesForm.get("guest_name_3");
      const guest_passport_1 =
        this.bookAirportVipPrivilegesForm.get("guest_passport_1");
      const guest_passport_2 =
        this.bookAirportVipPrivilegesForm.get("guest_passport_2");
      const guest_passport_3 =
        this.bookAirportVipPrivilegesForm.get("guest_passport_3");
      if (guest_num == 1) {
        guest_name_1.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
        }
        guest_name_2.clearValidators();
        guest_name_2.updateValueAndValidity();
        guest_passport_2.clearValidators();
        guest_passport_2.updateValueAndValidity();
        guest_name_3.clearValidators();
        guest_name_3.updateValueAndValidity();
        guest_passport_3.clearValidators();
        guest_passport_3.updateValueAndValidity();
      }
      if (guest_num == 2) {
        guest_name_1.setValidators([Validators.required]);
        guest_name_2.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
          guest_passport_2.setValidators([Validators.required]);
        }
        guest_name_3.clearValidators();
        guest_passport_3.clearValidators();
        guest_name_3.updateValueAndValidity();
        guest_passport_3.updateValueAndValidity();
      }
      if (guest_num == 3) {
        guest_name_1.setValidators([Validators.required]);
        guest_name_2.setValidators([Validators.required]);
        guest_name_3.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
          guest_passport_2.setValidators([Validators.required]);
          guest_passport_3.setValidators([Validators.required]);
        }
      }
    } else {
      this.guest_count_number = 0;
    }
    for (let i = 1; i < guest_num; i++) {
      this.filesList.push(i);
    }
  }

  bookAirportVipPrivileges() {
    let headers = new HttpHeaders();
    const files: Array<File> = this.filesToUpload;
    headers.set("Content-Type", null);
    headers.set("Accept", "multipart/form-data");
    let params = new HttpParams();
    const formData: FormData = new FormData();
    this.submitButtonSubmitted = true;
    this.bookAirportVipPrivilegesError = "";
    this.bookingTime();
    if (this.bookAirportVipPrivilegesForm.valid) {
      const data = {
        book_airport_vip_privileges: {
          bin_used:
            this.bookAirportVipPrivilegesForm.value.airport_vip_privileges_bin,
          city: this.bookAirportVipPrivilegesForm.value.city,
          first_name: this.bookAirportVipPrivilegesForm.value.first_name,
          last_name: this.bookAirportVipPrivilegesForm.value.last_name,
          mobile_number: this.userData.mobile,
          email: this.userData.email,
          booking_date: this.bookAirportVipPrivilegesForm.value.booking_date,
          booking_time: this.bookingTime(),
          travel_sector: this.bookAirportVipPrivilegesForm.value.travel_sector,
          assistance_type:
            this.bookAirportVipPrivilegesForm.value.assistance_type,
          airport: this.bookAirportVipPrivilegesForm.value.airport,
          bookingForGuest:
            this.bookAirportVipPrivilegesForm.value.bookingForGuest,

          passport: this.bookAirportVipPrivilegesForm.value.passport,
          guest_document:
            this.bookAirportVipPrivilegesForm.value.guest_document,
          agree_terms: this.bookAirportVipPrivilegesForm.value.agree_terms,
          contact_info: this.bookAirportVipPrivilegesForm.value.contact_info,
        },
      };
      data.book_airport_vip_privileges["guests"] = [];
      for (
        let index = 0;
        index < Number(this.bookAirportVipPrivilegesForm.value.guest_count);
        index++
      ) {
        data.book_airport_vip_privileges["guests"].push({
          guest_name:
            this.bookAirportVipPrivilegesForm.value[
              "guest_name_" + (index + 1)
            ],
          passport:
            this.bookAirportVipPrivilegesForm.value[
              "guest_passport_" + (index + 1)
            ],
        });
      }
      // for(let i =0; i < files.length; i++){
      //   data.book_airport_vip_privileges['uploads[]'] = files[i];
      // }
      // "guest_name_1": this.bookAirportVipPrivilegesForm.value.guest_name_1,
      // "guest_name_2": this.bookAirportVipPrivilegesForm.value.guest_name_2,
      // "guest_name_3": this.bookAirportVipPrivilegesForm.value.guest_name_3,
      // "guest_passport_1": this.bookAirportVipPrivilegesForm.value.guest_passport_1,
      // "guest_passport_2": this.bookAirportVipPrivilegesForm.value.guest_passport_2,
      // "guest_passport_3": this.bookAirportVipPrivilegesForm.value.guest_passport_3,
      // formData.append('data', JSON.stringify(data));
      formData.append(
        "airport_name",
        this.bookAirportVipPrivilegesForm.value.airport
      );
      formData.append(
        "bin_used",
        this.bookAirportVipPrivilegesForm.value.airport_vip_privileges_bin
      );
      formData.append("city", this.bookAirportVipPrivilegesForm.value.city);
      formData.append(
        "first_name",
        this.bookAirportVipPrivilegesForm.value.first_name ||
          this.bookAirportVipPrivilegesForm.controls.first_name.value
      );
      formData.append(
        "last_name",
        this.bookAirportVipPrivilegesForm.value.last_name ||
          this.bookAirportVipPrivilegesForm.controls.last_name.value
      );
      formData.append("email", this.bookAirportVipPrivilegesForm.value.email);
      formData.append(
        "mobile_number",
        this.bookAirportVipPrivilegesForm.value.mobile_number
      );
      formData.append(
        "booking_date_time",
        moment(
          this.bookAirportVipPrivilegesForm.value.booking_date.format(
            "DD/MM/YYYY"
          ) +
            " " +
            this.bookAirportVipPrivilegesForm.value.booking_time_hour +
            " " +
            this.bookAirportVipPrivilegesForm.value.booking_time_minute,
          "DD/MM/YYYY HH:mm a"
        ).format("ddd, D MMM YYYY h:mm:ss a")
      );
      formData.append(
        "travel_sector",
        this.bookAirportVipPrivilegesForm.value.travel_sector
      );
      formData.append(
        "assistance_type",
        this.bookAirportVipPrivilegesForm.value.assistance_type
      );
      formData.append(
        "airport",
        this.bookAirportVipPrivilegesForm.value.airport
      );
      formData.append(
        "booking_for_guest",
        this.bookAirportVipPrivilegesForm.value.bookingForGuest
      );
      formData.append(
        "guest_count",
        this.bookAirportVipPrivilegesForm.value.guest_count
      );
      // formData.append("guest_name_1", this.bookAirportVipPrivilegesForm.value.guest_name_1);
      // formData.append("guest_name_2", this.bookAirportVipPrivilegesForm.value.guest_name_2);
      // formData.append("guest_name_3", this.bookAirportVipPrivilegesForm.value.guest_name_3);
      // formData.append("guest_passport_1", this.bookAirportVipPrivilegesForm.value.guest_passport_1);
      // formData.append("guest_passport_2", this.bookAirportVipPrivilegesForm.value.guest_passport_2);
      // formData.append("guest_passport_3", this.bookAirportVipPrivilegesForm.value.guest_passport_3);
      // if(data.book_airport_vip_privileges['guests']) formData.append('guests',data.book_airport_vip_privileges['guests'])
      formData.append(
        "passport",
        this.bookAirportVipPrivilegesForm.value.passport
      );
      for (let i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i]["name"]);
      }
      formData.append(
        "guests",
        JSON.stringify(data.book_airport_vip_privileges["guests"])
      );
      formData.append(
        "agree_terms",
        this.bookAirportVipPrivilegesForm.value.agree_terms
      );
      formData.append(
        "contact_info",
        this.bookAirportVipPrivilegesForm.value.contact_info
      );
      //
      this.makingEnquiry = true;
      this._commonService.airportVipPrivilegesUpdate(formData).subscribe(
        (response) => {
          this.makingEnquiry = false;
          this.router.navigate([
            "airport-vip-privileges",
            response.data.data.id,
            "checkout",
          ]);
          this.dialogRef.close();
        },
        (err) => {
          this.bookAirportVipPrivilegesError = "";
          if (err.json().booking_date) {
            this.bookAirportVipPrivilegesError = err
              .json()
              .booking_date.join(",");
          }
          if (
            this.bookAirportVipPrivilegesError == "" ||
            this.bookAirportVipPrivilegesError == undefined
          ) {
            this.bookAirportVipPrivilegesError =
              "Something went wrong! Please try again after sometime.";
          }
          this.makingEnquiry = false;
          this.toastr.warning(this.bookAirportVipPrivilegesError);
        }
      );
    } else {
      for (const key of Object.keys(
        this.bookAirportVipPrivilegesForm.controls
      )) {
        if (this.bookAirportVipPrivilegesForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
    }
  }

  upload(event: any) {
    // //let files = event.target.files;
    // const formData: any = new FormData();
    // const files: Array<File> = event.target.files;
    // //check file is valid
    for (var i = 0; i < event.target.files.length; i++) {
      if (!this.validateFile(event.target.files[i].name)) {
        console.log("Selected file format is not supported");
        this.guest_document_error = true;
        this.guest_document_error_message =
          "Selected file format is not supported, Please upload JPG, JPEG, PNG or PDF";
        return false;
      }
    }
    this.filesToUpload = <Array<File>>event.target.files;

    // if (event.target.files.length > 0) {
    //   //const file = event.target.files[0];
    //   this.uploadForm = event.target.files[0];
    //   for (var i = 0; i < files.length; i++) {
    //     this.uploadForm.append("file", files[i] )
    //   }
    // }

    // let fData: FormData = new FormData;

    // for (var i = 0; i < files.length; i++) {
    //     fData.append("file", files[i]);
    // }

    // this.fileData = event.target.files[0];
    // this.fileDataName = this.fileData.name;

    // var _data = {
    //     filename: 'Airport Vip Privileges File' + "",
    //     id: '0001'
    // }

    // fData.append("data", JSON.stringify(_data));
    // this._service.uploadFile(fData).subscribe(
    //     response => console.log(response),
    //     error => console.log(error)
    // )
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "png"
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCityList() {
    return this.airportList
      .map((x) => x.city)
      .filter((x, i, a) => x && a.indexOf(x) === i);
  }

  filterAirport() {
    let data = this.airportList;
    if (this.bookAirportVipPrivilegesForm.value.city) {
      data = data.filter(
        (x) => x.city == this.bookAirportVipPrivilegesForm.value.city
      );
    }

    if (this.bookAirportVipPrivilegesForm.value.travel_sector == "Domestic") {
      data = data.filter((x) => this.isEnabled(x.domestic));
    } else if (
      this.bookAirportVipPrivilegesForm.value.travel_sector == "International"
    ) {
      data = data.filter((x) => this.isEnabled(x.international) && (this.isEnabled(x.arrival) || this.isEnabled(x.departure)));
    } else {
      data = data;
    }
    if (
      this.bookAirportVipPrivilegesForm.value.assistance_type == "Departure"
    ) {
      data = data.filter((x) => this.isEnabled(x.departure));
    } else if (
      this.bookAirportVipPrivilegesForm.value.assistance_type == "Arrival"
    ) {
      data = data.filter((x) => this.isEnabled(x.arrival));
    } else {
      data = data;
    }

    // if (data.length == 1) { // COMMENT: commented this as when filtering the aiport based on userSelection we are prefilling the first airport even before considering travel sector
    //   this.bookAirportVipPrivilegesForm.controls["airport"].setValue(
    //     data[0].airport
    //   );
    // }
    // if(!this.bookAirportVipPrivilegesForm.value.airport){
    //   this.bookAirportVipPrivilegesForm.controls['airport'].setValue(data[0].airport);
    // } else if (data.filter((x) => { return x.airport == this.bookAirportVipPrivilegesForm.value.airport }).length > 0){
    //   this.bookAirportVipPrivilegesForm.controls['airport'].setValue(data[0].airport);
    // } else if (data.filter((x) => { return x.airport == this.bookAirportVipPrivilegesForm.value.airport }).length == 0){
    //   this.bookAirportVipPrivilegesForm.controls['airport'].setValue(data[0].airport);
    // }
    return data;
  }

  private isEnabled(value): boolean {
    return value === 'Y';
  }

  public isServiceAvailable(key: string): boolean {
    return this.isEnabled(this.airportList?.filter((a) => 
      a.city === this.bookAirportVipPrivilegesForm.value.city &&
      this.isEnabled(a[this.bookAirportVipPrivilegesForm.value.travel_sector.toLowerCase()]) &&
      this.isEnabled(a[key])
    )?.[0]?.[key]);
  }

  setTravelSectorFilter() {
    let data = this.airportList.filter(
      (x) => x.city == this.bookAirportVipPrivilegesForm.value.city
    );
    this.travelType = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].domestic === "Y" && !this.travelType.includes("Domestic")) {
        this.travelType.push("Domestic");
      } else if (
        data[i].international === "Y" &&
        !this.travelType.includes("International")
      ) {
        this.travelType.push("International");
      }
    }
  }

  filterHourTimeList() {
    let min_available_date = moment().add(2, "days");
    var current_booking_date = this.booking_date.value;
    var fullList = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ];

    if (current_booking_date > min_available_date) {
      return fullList;
    } else {
      let current_hour = min_available_date.get("hours");
      var arr_hour = fullList.filter((x) => {
        return x >= current_hour;
      });
      return arr_hour;
    }
  }

  filterMinutesTimeList() {
    var minList = [0, 15, 30, 45];
    let available_date = moment();
    let min_available_date = moment().add(2, "days");
    var current_booking_date = this.booking_date.value;
    let current_min = available_date.get("minutes");
    let current_hour = available_date.get("hours");
    var booking_time = this.booking_time_hour.value;
    if (
      booking_time > current_hour ||
      current_booking_date > min_available_date
    ) {
      return minList;
    } else {
      var arr_minutes = minList.filter((x) => {
        return x > current_min;
      });
      return arr_minutes;
    }
  }

  setUserCategoryValidators() {
    const travel_sector =
      this.bookAirportVipPrivilegesForm.get("travel_sector");
    const passport = this.bookAirportVipPrivilegesForm.get("passport");
    if (travel_sector.value === "International") {
      passport.setValidators([Validators.required]);
    } else {
      passport.setValidators(null);
    }
    passport.updateValueAndValidity();
  }

  setCityAirportValidators(selectedValue) {
    // this.apList =  this.airportList.find(x => x.city === selectedValue).airport;
    this.airportName = this.filterAirport()[0][0];
    this.selectedCity = selectedValue;
  }

  filetrTime(event, time) {
    console.log(time.target.value);
  }

  bookingTime() {
    let time = moment();
    time.set({
      hour: this.booking_time_hour.value,
      minute: this.booking_time_minute.value,
    });

    return time.format("hh:mm A");
  }

  getExpiration() {
    var expiration = localStorage.getItem("expires_at");
    var expiresAt = JSON.parse(expiration);
    return moment(new Date(expiresAt));
  }
}
