import { Injectable, Output, EventEmitter, Directive } from '@angular/core'

@Directive()
@Injectable()
export class User {

  @Output() currentUser: EventEmitter<boolean> = new EventEmitter();

  toggle(val) {
    this.currentUser.emit(val);
  }

}