import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appOutsideClicked]",
})
export class OutsideClickedDirective {
  @Output() clickedOutsideRefElement = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener("document:click", ["$event.target"])
  public onClick(target) {
    if (target.id !== "clickException") {
      const clickedInsideRefElement =
        this.elementRef.nativeElement.contains(target);
      if (!clickedInsideRefElement) {
        this.clickedOutsideRefElement.emit();
      }
    }
  }
}
