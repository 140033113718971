import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonService } from "./common-services";
import { AuthenticationComponent } from "../authentication/authentication.component";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "./authentication.service";


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  isLogOutSessionInitiated: boolean = false;

  constructor(
    private router: Router,
    private _commonService: CommonService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        'X-api-Id': 'axis',
        'X-Client-Id': environment.clientId,
        'X-Project-Id': environment.projectId,
        // 'X-Country-Id': localStorage.getItem("country_id") || environment.countryId
      }
    });
    if (localStorage.getItem('token')) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404) {
            // this.router.navigate(["error"]);
          } else if (error["status"] == 401) {
            if(localStorage.getItem("currentUser") && !this.isLogOutSessionInitiated) {
              this.isLogOutSessionInitiated = true;
              this._commonService.logout().then((response) => {
                localStorage.removeItem("currentUser");
                this.isLogOutSessionInitiated = false;
              }, error => {
                if(error?.status == 401) {
                  localStorage.removeItem("currentUser");
                }
                this.isLogOutSessionInitiated = false;
              });
            }
            if (!error.url.endsWith('/cs/v1/user') && !this.authenticationService.isAuthenticationComponentOpen) {
              this.dialog.open(AuthenticationComponent, {
                width: "500px"
              });
            }
          }
        }
      )
    );
  }

}
