// export const environment = {
//   production: true
// };
export const environment = {
  production: true,
  SENTRY_ORG:"rzp",
  SENTRY_PROJECT:"walletbuddy_axis_new",
  SENTRY_AUTH_TOKEN:"sntrys_eyJpYXQiOjE3MTc0MDYyOTYuNjA0NzUsInVybCI6Imh0dHBzOi8vc2VudHJ5LmlvIiwicmVnaW9uX3VybCI6Imh0dHBzOi8vdXMuc2VudHJ5LmlvIiwib3JnIjoicnpwIn0",
  sentryDsn: "https://8dbd42db54e3d1ef476cfb5264abee07@o515678.ingest.us.sentry.io/4507351240540160",
  apiUrlBsV1: "https://api.poshvine.com/bs/v1/",
  apiUrlCsV1: "https://api.poshvine.com/cs/v1/",
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlPsV1: "https://api.poshvine.com/ps/v1/",
  apiUrl: "https://rewards-api.poshvine.com/api/v1/",
  sandboxUrl: "https://rewards-api.poshvine.com/",
  offersLink: "https://offers.extraordinaryweekends.com/",
  corePath: "https://offers-api.poshvine.com/core_service/v1",
  offerPath: "https://offers-api.poshvine.com/offer_service/v1",
  clientId: "095202e0-260e-463f-926e-f9bfec6c74c7",
  projectId: "69b5e484-64b6-4685-8de9-05d3428ce0d4",
};
