<app-header class="default-head" *ngIf="resetHeader"></app-header>

<section class="half-banner-golf">
    <div class="container">
        <div class="row">
            <div class="slider-content">
                <div class="golf-card-block-left">
                    <h1 style="color:white;">Tee-off with a master stroke</h1>
                    <p style="color:white;">Enjoy some of the most picturesque golf destinations with complimentary
                        rounds of golf.</p>
                    <div class="send-inquiry-button">
                        <div class="btn_container">
                            <button class="btn btn-primary" mat-button (click)="bookGolfFamiliar(true)"
                                [disabled]="cardValidatingFamiliar">Familiar with the Game
                                <i [class]="cardValidatingFamiliar ? 'fa fa-spinner fa-spin bin-loader' : ''"></i>
                            </button>
                            <div class="desc">Book a Golf Game
                            </div>
                        </div>
                        <div class="btn_container">
                            <button class="btn btn-primary" mat-button (click)="bookGolf(false)"
                                [disabled]="cardValidating">New to the Game
                                <i [class]="cardValidating ? 'fa fa-spinner fa-spin bin-loader' : ''"></i>
                            </button>
                            <div class="desc">Book a Golf Lesson
                            </div>
                        </div>
                        <br>
                        <input type="hidden" id="golf-experience" value="false">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row slider-mobile" style="margin-top: 20px">
            <div class="slider-content"><a (click)="scroll('listing-section')" class="scroll-to-golf-privileges"
                    style="color:white;">See List of Golf Courses and Axis Bank Cardholder Benefits.</a>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <!-- <a href="" style="background-image: url("https://daks2k3a4ib2z.cloudfront.net/5907c9a38b10c85428c0bc77/5928567a8570da37ffccd207_icon_down_arrow.svg") "></a> -->
            </div>

        </div>
    </div>
</section>
<section class="golf-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 benefits-section" id="benefits-section">
                <h3>Axis Bank Golf Program Benefits:</h3>
                <div class="card-list-on-golf-page">
                    <ul class="list-styled">
                        <li>Axis Bank Burgundy Private Credit Cardholders enjoy 50 complimentary rounds of golf per
                            year.</li>
                        <li>Axis Bank Reserve Credit Cardholders enjoy 50 complimentary rounds of golf per year w.e.f
                            1st April 2020.</li>
                        <li>Axis Bank SELECT Credit Cardholders enjoy 6 complimentary rounds of golf per year.</li>
                        <li>Axis Bank Vistara Infinite Credit Cardholders enjoy 6 complimentary rounds of golf per year.
                        </li>
                        <li>Axis Bank Vistara Signature Credit Cardholders enjoy 3 complimentary rounds of golf per
                            year.</li>
                        <!-- <li>Axis Bank Executive Corporate Cardholders enjoy 3 complimentary rounds of golf per year.</li> -->
                        <li>For the exclusive offer, terms and conditions apply.</li>
                    </ul>
                </div>
                <br>
                <h3 id="listing-section">List of Golf Courses</h3>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Ahmedabad</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Kalhaar Blues & Greens</p>
                            <p>Glade One</p>
                            <p>Kensville Golf Club</p>
                            <p>Gulmohur Greens Golf & Country Club</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Gurgaon</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Classic Golf & Country Club</p>
                            <p>Golden Greens Golf Resort</p>
                            <p>Karma Lakeland</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Bangalore</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Prestige Golfshire</p>
                            <p>Zion Hills (Champions Reef)</p>
                            <p>Clover Greens</p>
                            <p>Prestige Augusta Golf Village</p>
                            <p>Eagleton Golf Village</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Jaipur</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Royal Jaipur Golf Club</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Jamshedpur</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                        </div>
                    </div>
                </div> -->
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Delhi</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Sirifort</p>
                            <p>Qutab Golf Course</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Mumbai</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Kharghar Golf Club</p>
                            <p>Premium Golf Club in Mumbai</p>
                            <p>Golden Swan Academy</p>
                            <p>9 Aces Golf Greens Academy</p>
                            <p>Let's Golf Learning & Training facility - Mumbai</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Noida</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Jaypee Greens Golf Club</p>
                            <p>Jaypee Wishtown Golf Club</p>
                            <p>Noida Golf Stadium</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Pune</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Poona Golf Club</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Hyderabad</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Boulder Hills Golf Club </p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Chandigarh</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Panchkula Golf Club</p>
                        </div>
                    </div>
                </div>
                <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Kolkata</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Premium Golf Club in Kolkata</p>
                            <p>Protouch Golf Academy</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="golf-program-box">
                    <div class="row row-15">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <strong>Chennai</strong>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <p>Madras Gymkhana Club</p>
                            <p>AKDR Golf Village</p>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
        <div class="col-lg-6 offset-lg-3">
            <div class="golf-program-details-footer">
                <p>
                    * The golf rounds are complimentary only for the Primary card holder. Bookings will need to be made
                    4 days in advance. For detailed T&amp;Cs, please <a
                        href="./assets/Golf_Terms_and_Conditions_new.pdf" target="blank">click here.</a>
                </p>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>