import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import { Subscription } from "rxjs";
import { environment } from "./../../../../environments/environment";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ToastrService } from "ngx-toastr";
import { debounceTime, map, take } from "rxjs/operators";
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Pipe,
  PipeTransform,
  AfterViewInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../services/common-services";
import { FormatTimePipe } from "../services/formatTime";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Loader } from "../services/loader";

import { ActivatedRoute } from "@angular/router";
declare var JSEncrypt: any;
declare var $: any;
import * as moment from "moment";
import { appConstants } from "../../../app.constants";
import { AuthenticationService } from "../services/authentication.service";

@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.scss"],
  providers: [CommonService, FormatTimePipe],
  // encapsulation: ViewEncapsulation.None
})
export class AuthenticationComponent implements OnInit {
  mobilePattern = /^\d{10}$/;
  mobile = new UntypedFormControl("", [
    Validators.required,
    Validators.pattern(this.mobilePattern),
  ]);
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email = new UntypedFormControl("", [
    Validators.required,
    Validators.pattern(this.emailPattern),
  ]);
  name = new UntypedFormControl("", [Validators.required]);
  checkUserSubscription: Subscription;
  generateOtpSubscription: Subscription;
  type = "login";
  loginSubmit = false;
  checkUserLoader: boolean = false;
  userAlreadyExist: boolean = false;
  tnc_check: boolean = false;
  loginUser;
  errorMsg: string = "";
  otpErrorMsg: string = "";
  signupSubmit = false;
  interval;
  otpTimer = 180;
  resendActive = false;
  progress = false;
  otp1: string = "";
  otpSubmit = false;
  bin: any;
  showBinValidation: boolean = true;
  userId: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      border: "none",
      "border-bottom": "1px solid black",
      "border-radius": "0px",
      "font-size": "1em",
      width: "30px",
    },
  };
  firstInput = new UntypedFormControl();
  secondInput = new UntypedFormControl();
  @ViewChild("firstInputField") firstInputField: ElementRef;
  @ViewChild("secondInputField")
  secondInputField: ElementRef;
  binValidating: boolean = false;
  @ViewChild("otpField") otpField: ElementRef;
  @ViewChild("signinModalClose") myDiv1: ElementRef;
  @ViewChild("signupModalClose", { static: true }) myDiv2: ElementRef;
  @ViewChild("clone") template;
  @ViewChild("container", { read: ViewContainerRef }) container;
  str: any;
  getotp: any;
  otpForm: UntypedFormGroup;
  mail_error: any;
  otp_tocken: any;
  getotpresponse: any;
  card_error: any;
  timerCount: any;
  time: any;
  phone: any;
  number: any;
  page_loading: any;
  signupLoading: any;
  signupForm: UntypedFormGroup;
  signinForm: UntypedFormGroup;
  mobileForm: UntypedFormGroup;
  addCardForm: UntypedFormGroup;
  otpScreen: any;
  tocken: any;
  mobileNumber: any;
  signupScreen: any;
  emailScreen: any;
  nameStatus: any;
  birth_day: any;
  yearmodal: any;
  monthmodal: any;
  daymodal: any;
  currentUser: any;
  countDown: any;
  counter: any;
  tick: any;
  resetCountDown: any;
  resetCounter: any;
  resetTick: any;
  otpError: any;
  confirmationToken: any;
  addedBin: any;
  imageSrc: any;
  logoSource: any;
  bankLogoSource: any;
  signinLoading: any;
  networkLogo: any;
  cardData: any;
  cardError: any;
  resetMobileNumber: string;
  otpResetError: any;
  resetLoading: boolean = false;
  apiLoading: boolean = false;
  addedCards: number = 0;
  loginMobileSet: boolean = false;
  userStatus = "login";
  cardMap: any[] = appConstants.cards;
  mobileNumberMaxLength = 10;
  mobileNumberMinLength = 10;
  validateOtpSubscription: Subscription;
  otpOpen = false;
  showSignInPrifix = true;
  cardName;
  paramLink: any;
  @Input() signinOpen;
  @Input() signupOpen;
  @Output() modalClosed = new EventEmitter<any>(true);

  constructor(
    private _commonService: CommonService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loader: Loader,
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<AuthenticationComponent>,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this.getotp = true;
    this.getotpresponse = false;
    this.signupScreen = true;
    this.otpScreen = false;
    this.emailScreen = false;
    this.yearmodal = "";
    this.monthmodal = "";
    this.daymodal = "";

    this.signupForm = fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      name: ["", [Validators.required]],
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.minLength(10),
        ],
      ],
    });

    this.otpForm = fb.group({
      otp: [
        "",
        [Validators.required, Validators.minLength(6), Validators.minLength(6)],
      ],
    });

    this.mobileForm = fb.group({
      mobile: ["", Validators.required],
    });

    this.addCardForm = fb.group({
      bin: ["", Validators.required],
    });

    this.signinForm = fb.group({
      signinEmail: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      signinPassword: [""],
    });

    this.signinForm
      .get("signinEmail")
      .valueChanges.subscribe((mode: string) => {
        console.log(/^\d+$/.test(mode));
        if (!/^\d+$/.test(mode) || mode.length > 10) {
          this.signinForm.get("signinEmail").clearValidators();
          this.signinForm
            .get("signinEmail")
            .setValidators([
              Validators.required,
              Validators.pattern(this.emailPattern),
            ]);
          // this.showSignInPrifix = false;
        } else {
          this.signinForm.get("signinEmail").clearValidators();
          this.signinForm
            .get("signinEmail")
            .setValidators([
              Validators.required,
              Validators.minLength(10),
              Validators.maxLength(10),
            ]);
          // this.showSignInPrifix = true;
          if (mode.length == 10) {
            this.loginMobileSet = true;
          } else {
            if (this.loginMobileSet) {
              this.signinForm.get("signinPassword").clearValidators();
              this.signinForm.get("signinPassword").setValue("");
              this.signinForm.get("signinPassword").updateValueAndValidity();
              this.loginMobileSet = false;
              this.counter = 0;
              this.otpError = undefined;
            }
          }
        }
      });

    dialogRef.backdropClick().subscribe(() => {
      this.onNoClick();
    });
    this.authenticationService.isAuthenticationComponentOpen = true;
  }

  ngOnDestroy() {
    this.authenticationService.isAuthenticationComponentOpen = false;
  }

  ngOnInit() {
    this.loader.change.subscribe((isLoading) => {
      this.page_loading = !isLoading;
    });

    $("#signup-form").on("hidden.bs.modal", () => {
      console.log("signup-form closed");
      this.modalClosed.emit(true);
    });

    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
    }
  }

  onNoClick(): void {
    this.dialogRef.close("redirect");
  }

  continue() {
    this.binValidating = true;
    const val = this.firstInput.value + this.secondInput.value;
    this.bin = val;
    this.errorMsg = "";
    this._commonService.checkBinValidation(val).subscribe((user: any) => {
      if (user.success) {
        this.showBinValidation = false;
        this.cardName = this.getCardName(val);
      } else {
        this.firstInput.setValue("");
        this.secondInput.setValue("");
        this.errorMsg = "Invalid bin";
      }
      this.binValidating = false;
    });
  }

  getCardName(card_no) {
    let image = null;
    this.cardMap.forEach((element) => {
      if (
        element.bin.toString() == card_no.toString() ||
        element.bin.toString() == card_no.toString().substring(0, 6)
      ) {
        image = element.name;
      }
    });
    return image;
  }

  removeLetter(str) {
    str.target.value = str.target.value.replace(/\D/g, "");
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  setSession(user) {
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUser = user;
    this.loader.userLoggedIn(true);
    let el: HTMLElement = this.myDiv2.nativeElement as HTMLElement;
    el.click();
    this.signupLoading = false;
    this.route.queryParams.subscribe((params) => {
      if (
        params.refferer &&
        params.refferer == "offers" &&
        params.reffererLink
      ) {
        this.paramLink = params.reffererLink;
        let token = localStorage.getItem("access-token");
        if (token) {
          this.goToOffers();
        }
      }
    });
  }

  goToOffers() {
    let token = localStorage.getItem("access-token");
    let uid = localStorage.getItem("uid");
    let client = localStorage.getItem("client");
    var offersLink = environment.offersLink;
    if (this.paramLink) {
      offersLink = this.paramLink;
    }
    if (token) {
      window.location.href =
        offersLink + "?code=" + token + "&uid=" + uid + "&client=" + client;
    } else {
      window.location.href = offersLink + "";
    }
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateMobile(form, field, event) {
    if (
      !this.isNumber(event) ||
      (form.get(field).value && form.get(field).value.length > 9)
    ) {
      return false;
    }
  }

  validateCard(event) {
    if (this.addCardForm.value.bin.length > 9 || !this.isNumber(event)) {
      return false;
    } else {
      var current = this.addCardForm.value.bin.split("  ").join("");
      if (current.length > 0) {
        current = current.match(new RegExp(".{1,4}", "g")).join("  ");
      }
      this.addCardForm.controls["bin"].setValue(current);
    }
  }

  onFirstInputChange() {
    this.firstInput.setValue(this.firstInput.value.replace(/\D/g, ""));

    if (this.firstInput.value && this.firstInput.value.length == 4) {
      this.secondInput.setValue("");
      this.secondInputField.nativeElement.focus();
    }
  }

  onSecondInputChange(event) {
    this.secondInput.setValue(this.secondInput.value.replace(/\D/g, ""));
    if (!this.secondInput.value) {
      if (event && event.keyCode && event.keyCode == 8) {
        this.firstInputField.nativeElement.focus();
      }
    }
  }

  checkUser(event) {
    if (this.checkUserSubscription) {
      this.checkUserSubscription.unsubscribe();
    }
    if (this.generateOtpSubscription) {
      this.generateOtpSubscription.unsubscribe();
    }
    this.loginSubmit = false;
    this.type = "login";
    this.checkUserLoader = false;
    this.userAlreadyExist = false;
    if (this.mobile.value && this.mobile.value.length == 10) {
      this.checkUserLoader = true;
      this._commonService.checkUser(this.mobile.value, this.bin).subscribe((res: any) => {
        if (res) {
          this.userAlreadyExist = true;
          this.userId = res.id;
          this.onContinue();
        }
        this.checkUserLoader = false;
      });
    }
  }

  onContinue() {
    if (this.userAlreadyExist && !this.progress) {
      this.sendOTP(this.mobile);
    } else if (!this.userAlreadyExist && !this.progress) {
      this.signupUser(
        this.mobile.value,
        this.email.value,
        this.name.value,
        this.tnc_check
      );
    }
  }

  sendOTP(phone) {
    this.loginSubmit = true;
    if (phone.invalid) return;
    this.generateOtpSubscription = this._commonService
      .signinEmail(this.userId, phone.value)
      .subscribe(
        (res: any) => {
          this.loginUser = phone.value;
          this.loginSubmit = false;
          this.startTimer();
          if (res) {
            this.errorMsg = "";
            this.otpErrorMsg = "";
            this.type = "otp";
            this.otp();
          } else {
            this.errorMsg =
              "OTP Request limit exceeded. Please try after 30 mins.";
          }
        },
        (error) => {
          this.errorMsg =
            "OTP Request limit exceeded. Please try after 30 mins.";
          this.loginSubmit = false;
        }
      );
  }

  signupUser(phone, email, name, tnc_check) {
    this.signupSubmit = true;
    if (phone.invalid) return;
    if (email.invalid) return;
    if (tnc_check) {
      this.errorMsg = "";
      this.progress = true;
      this.signinLoading = true;
      this._commonService.signupEmail(phone, email, name, "91", "IN").subscribe(
        (res: any) => {
          this.progress = false;
          this.userId = res.id;
          this.loginUser = res.mobile;
          this.signupSubmit = true;
          this.userStatus = "activation";
          this.otp();
          this.startTimer();
          this.signinLoading = false;
        },
        (err) => {
          this.progress = false;
        }
      );
    } else {
      this.errorMsg = "Please accept terms and conditions.";
    }
  }

  addUserCard() {
    return new Promise((resolve) => {
      this._commonService.addUserCard(this.bin).subscribe(
        (res: any) => {
          if (res && res.data) {
            res.data.attributes &&
              res.data.attributes.session_token &&
              localStorage.setItem("token", res.data.attributes.session_token);
            localStorage.setItem(
              "user_card_details",
              JSON.stringify(res.data.attributes)
            );
            localStorage.setItem("user_card_id", res.data.id);
            this._commonService.getUserCards();
          }
          resolve(true);
        },
        (err) => {
          console.log(
            "ERROR in Adding user card or when fetching user cards",
            err
          );
        }
      );
    });
  }

  signInUser(otp) {
    if (otp.length === 0) {
      this.otpErrorMsg = "OTP is required";
      this.otp1 = "";
    } else if (otp.length > 0 && otp.length < 6) {
      this.otpErrorMsg = "OTP must contain 6 digits";
      this.otp1 = "";
    } else {
      this.otpErrorMsg = "";
      this.progress = true;
      this.validateOtpSubscription = this._commonService
        .validateUserOtp(this.userId, otp, this.userStatus)
        .pipe(debounceTime(1000))
        .subscribe(
          (response: any) => {
            if (response && response.token) {
              localStorage.setItem("token", response.token);
              this.addUserCard().then((res) => {
                this._commonService
                  .getUserDetails()
                  .subscribe((result: any) => {
                    localStorage.setItem("country_id", result.country_id);
                    var user = {
                      name: result.first_name,
                      mobile: result.mobile_number,
                      email: result.email,
                      bin: this.firstInput.value + this.secondInput.value,
                      token: result.token,
                    };
                    this.setSession(user);
                    this.otpSubmit = false;
                    this.errorMsg = "";
                    this.toastr.success("Logged in successfully.");
                    this.dialogRef.close("true");
                    this.closeAll();
                  });
              });
            } else {
              this.otpSubmit = false;
              this.otpErrorMsg = "Please check the entered OTP.";
              this.progress = false;
              this.otpTimer = 0;
              if (!response.wrong_attempts_left) {
                let time;
                if (
                  this._commonService.clientDetails &&
                  this._commonService.clientDetails.attributes &&
                  this._commonService.clientDetails.attributes.configs
                    .otp_wrong_attempts_lock_duration
                ) {
                  time =
                    this._commonService.clientDetails.attributes.configs
                      .otp_wrong_attempts_lock_duration;
                }
                if (time) {
                  this.errorMsg = `Sorry, You have reached the max OTP attempts. Please retry after ${time} minutes`;
                  this.type = "login";
                  this.mobile.setValue("");
                }
              }
            }
          },
          (error) => {
            this.otpSubmit = false;
            this.otpErrorMsg = "Please check the entered OTP.";
            this.progress = false;
          }
        );
    }
  }

  resendOTP() {
    this.otp1 = "";
    this.resendActive = false;
    setTimeout(() => {
      this.sendOTP(this.mobile.value);
    }, 3000);
  }

  otp() {
    this.otpErrorMsg = "";
    this.type = "otp";
    setTimeout(() => this.otpField.nativeElement.focus(), 500);
  }

  clearOtp() {
    this.otp1 = '';
    this.otpErrorMsg = "";
    this.errorMsg = "";
  }

  removeLetterOTP() {
    if (this.otp1 && this.otp1.length == 6) {
      this.otpErrorMsg = "";
      this.errorMsg = "";
      if (this.validateOtpSubscription) {
        this.validateOtpSubscription.unsubscribe();
      }
      this.otpSubmit = false;
      this.validateOtp();
    }
  }

  validateOtp() {
    if (this.otp1 && this.otp1.length == 6) {
      this.signInUser(this.otp1);
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.otpTimer > 0) {
        this.otpTimer--;
      } else {
        this.otpTimer = 180;
        this.resendActive = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  isContinueDisabled() {
    if (this.checkUserLoader || this.mobile.invalid) {
      return true;
    }
    if (
      !this.userAlreadyExist &&
      (this.email.invalid ||
        !this.tnc_check ||
        this.name.invalid ||
        this.mobile.invalid)
    ) {
      return true;
    }
    return false;
  }

  changeNonStop(event) {
    this.tnc_check = event.target.checked;
    this.errorMsg = "";
  }

  logout() {
    this._commonService.deleteToken();
    this.clearUser();
    window.location.href = "/";
  }

  clearUser() {
    localStorage.clear();
    this.currentUser = undefined;
  }

  closeAll() {
    this.signinOpen = false;
    this.signupOpen = false;
    this.otpOpen = false;
  }
}
