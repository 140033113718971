import { Injectable, Output, EventEmitter, Directive } from '@angular/core'

@Directive()
@Injectable()
export class Loader {

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() cardChange: EventEmitter<boolean> = new EventEmitter();
  @Output() openAddCard: EventEmitter<boolean> = new EventEmitter();
  @Output() userPresent: EventEmitter<boolean> = new EventEmitter();
  @Output() userUpdated: EventEmitter<boolean> = new EventEmitter();

  toggle(loading) {
    this.change.emit(loading);
  }

  cardChanged() {
    this.cardChange.emit(true);
  }

  openCard() {
    this.openAddCard.emit();
  }

  userLoggedIn(status) {
    this.userPresent.emit(status);
  }

  userInfoUpdated(status) {
    this.userUpdated.emit(status);
  }

}