import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { routing } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModuleModule } from "./pages/common/common-module.module";
import { PaymentModule } from "./pages/common/payment/payment.module";

import { FilterPipe } from "./pages/common/services/filter.pipe";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { ArraySortPipe } from "./pages/common/services/sortPipe";
import { Loader } from "./pages/common/services/loader";
import { User } from "./pages/common/services/user";

import { AirportVipPrivilegesCheckoutComponent } from "./pages/airport-vip-privileges/airport-vip-privileges-checkout/airport-vip-privileges-checkout.component";
import { AirportTransfersCheckoutComponent } from "./pages/airport-transfers/airport-transfers-checkout/airport-transfers-checkout.component";
import { GolfCheckoutComponent } from "./pages/golf/golf-checkout/golf-checkout.component";

import { GroupByPipe } from "./pages/common/services/group-by.pipe";
import { GroupByPipeTwo } from "./pages/common/services/groupby.pipe";


import { DynamicFormComponent } from "./pages/common/dynamic-form/dynamic-form.component";
import { DynamicFormQuestionComponent } from "./pages/common/dynamic-form/dynamic-form-question.component";
import { AuthenticationComponent } from "./pages/common/authentication/authentication.component";
import { DataSharingService } from "./pages/common/services/data-sharing.service";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import {
  GolfComponent,
  DialogBookgolf,
  DialogBookgolfFamiliar,
} from "./pages/golf/golf/golf.component";
import {
  AirportVipPrivilegesComponent,
  DialogBookairportVipPrivileges,
} from "./pages/airport-vip-privileges/airport-vip-privileges/airport-vip-privileges.component";
import {
  AirportTransfersComponent,
  DialogBookairportTransfers,
} from "./pages/airport-transfers/airport-transfers/airport-transfers.component";
import { UniquePipe } from "./pages/common/services/unique";
import { AuthInterceptorService } from "./pages/common/services/auth-interceptor.service";
import { CommonService } from "./pages/common/services/common-services";
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GlobalConstants } from "./pages/common/services/global-variables";

// export const checkForUpdates = (swUpdate: SwUpdate): (() => Promise<any>) => {
//   return (): Promise<void> =>
//     new Promise((resolve) => {
//       console.log("isEnabled", swUpdate.isEnabled);
//       if(swUpdate.isEnabled){
//         swUpdate.checkForUpdate();
//         swUpdate.available.subscribe(() => {
//             window.location.reload();
//         });
//       }
//       resolve();
//     });
// };

@NgModule({
    declarations: [
        DynamicFormComponent,
        DynamicFormQuestionComponent,
        AppComponent,
        FilterPipe,
        ArraySortPipe,
        GolfCheckoutComponent,
        AirportVipPrivilegesCheckoutComponent,
        AirportTransfersCheckoutComponent,
        GroupByPipe,
        GroupByPipeTwo,
        GolfComponent,
        DialogBookgolf,
        DialogBookgolfFamiliar,
        AirportVipPrivilegesComponent,
        AirportTransfersComponent,
        DialogBookairportTransfers,
        DialogBookairportVipPrivileges,
        UniquePipe,
        AuthenticationComponent,
    ],
    imports: [
        CommonModuleModule,
        PaymentModule.forRoot(environment),
        routing,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatSnackBarModule,
        BrowserModule,
        BrowserAnimationsModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: "danger", // set defaults here
        }),
        HttpClientModule,
        ServiceWorkerModule.register('safety-worker.js', { enabled: environment.production }),
    ],
    providers: [
        Loader,
        User,
        DataSharingService,
        GlobalConstants,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        CommonService,
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: checkForUpdates,
        //     multi: true,
        //     deps: [SwUpdate] //dependencies
        // }
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        }, {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
    
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
