
<div class="pay-btn-box">
  <button class="btn saved-pay-bt pay-btn" type="button"
    [disabled]="paymentClicked"
    (click)="sendPayment()">Pay <span>₹{{amountPayable || 10}}</span>
    <i [class]="makingPayment ? 'fa fa-spinner fa-spin' : ''"></i>
  </button>
</div>

<div class="p-note">Please note that this website accepts payments only from Axis Bank Premium Cards. To see a
  list of applicable cards <a (click)="openCardDrop()" id="clickException">click here</a>.</div>

