<div class="modal show" id="signup-form" role="dialog" data-keyboard="true">
	<div class="modal-dialog modal-lg ">
		<div class="modal-content modal-bg relative">
			<button type="button" class="close" #signupModalClose data-dismiss="modal" aria-hidden="true" style="display:none">×</button>
			<div class="m-content-box relative">
				<div class="modal-logo"><img src="../../assets/axis-logo-white.png"></div>
				<div class="bin-container" style="display: inline-grid;" *ngIf="showBinValidation" [class]="showSignInPrifix ? 'form-group relative' : 'relative form-group'">
					<div class="enter-text">
					  Enter first 8 digits of your AXIS Bank Card
					</div>
					<form (submit)="continue()">
						<div class="card-block mar-top-10">
							<label>Card Number</label>
							<div class="flex main-flex">
							  <div class="col-3 p-0 pd-r7">
								<input type="text" #firstInputField numbersOnly autocomplete="new-password" [formControl]="firstInput"
								  minlength="4" maxlength="4" required class="form-control ng-touched ng-dirty ng-invalid text-center"
								  (input)="onFirstInputChange()" style="margin-top: 0;">
							  </div>
							  <div class="col-3 p-0 pd-r7 pd-l7">
								<input type="text" #secondInputField numbersOnly autocomplete="new-password" [formControl]="secondInput"
								  minlength="4" maxlength="4" required class="form-control ng-touched ng-pristine ng-invalid text-center"
								  (keydown)="onSecondInputChange($event)" style="margin-top: 0;">
							  </div>
							  <div class="col-3 p-0 pd-r7 pd-l7">
								<div class="form-single-container" style="height: inherit;">
								  <input type="text" placeholder="XXXX" disabled class="form-control text-center disabled grey-background" style="margin-top: 0;"></div>
							  </div>
							  <div class="col-3 p-0 pd-l7">
								<div class="form-single-container"  style="height: inherit;">
								  <input type="text" placeholder="XXXX" disabled class="form-control text-center disabled grey-background" style="margin-top: 0;"></div>
							  </div>
							</div>
						  </div>
						  <p class="invalid-feedback no-margin pad-bot-15">{{errorMsg}}</p>
					
					
						  <div class="signup-btn-box sign-up-footer">
							<!-- <button class="primary-btn w100 h100" mat-button [disabled]="firstInput.invalid || secondInput.invalid"
							  (click)="continue()">Continue</button> -->
	  
							  <button type="submit" class="btn" [disabled]="firstInput.invalid || secondInput.invalid" disabled (click)="continue()">
								  <i [class]="binValidating ? 'fa fa-spinner fa-spin' : ''"></i>
								  Continue
							  </button>
						  </div>
					</form>
			  
					<div class="flex last-b">
					  <div class="ll">Your Card parameters are used only to check eligibility and
						are encrypted</div>
						<div style="width: 80px;"><img alt="" class="img-fluid" src="assets/pci-dss.png"></div>
					</div>
				</div>


				<div class="single-box" *ngIf="!showBinValidation && cardName">
					<div class="card-name">{{cardName}}</div>
				</div>
				<div *ngIf="!showBinValidation" [class]="showSignInPrifix ? 'form-group relative show-prefix' : 'relative form-group'">
					<p class="ft-wt-500 pad-bot-6 ft-sz-12 grey m-0" style="position: absolute; top:-25px; left: 0;">Enter your mobile number</p>
					<span class="mobile-prefix">+91 </span>
					<input type="text" class="form-control"  (input)="removeLetter($event);checkUser($event)" autocomplete="off"
					maxlength="10"  minlength="10" type="tel" [formControl]="mobile" style="margin-top: 70px;">
					<div class="fa fa-spinner fa-spin bin-loader absolute mar-right-20" *ngIf="checkUserLoader || loginSubmit"></div>
				</div>
				<ng-container *ngIf="!userAlreadyExist && mobile.valid && !checkUserLoader">
					<div class="single-box">
						<p class="ft-wt-500 pad-bot-6 ft-sz-12 grey m-0" style="position: absolute; top:-25px; left: 0;">Enter Full Name</p>
						<input class="form-control" type="text" [formControl]="name" autosuggest="off">
					</div>
					<div class="single-box">
						<p class="ft-wt-500 pad-bot-6 ft-sz-12 grey m-0" style="position: absolute; top:-25px; left: 0;">Enter Email Address</p>
						<input class="form-control" type="email" [formControl]="email" (input)="type=''">
					</div>			
					<p class="field-message pad-top-16 pad-bot-5 m-0" *ngIf="!signupSubmit">
					  <label class="filter-label" style="display: inline-flex;">
						<input type="checkbox" class="mar-top-3" style="margin-right: 5px;" (change)="changeNonStop($event);type='signup';" [checked]="tnc_check"><span
						  class="checkmark"></span><span class="fnt-13 pad-left-10 width-label">
							<p style="margin: 0;">By creating this account, you agree to our <a href="/info/terms-and-conditions" target="_blank">Terms & Conditions</a> & <a href="/info/privacy-policy" target="_blank">Privacy Policy</a>.</p>
						</span>
					  </label>
					</p>
					<div class="signup-btn-box sign-up-footer" *ngIf="!signupSubmit">
						<button type="submit" class="btn" [disabled]="isContinueDisabled()" disabled (click)="onContinue()">
							<i [class]="signinLoading ? 'fa fa-spinner fa-spin' : ''"></i>
							Sign up
						</button>
					</div>
				</ng-container>
				<p class="invalid-feedback no-margin" *ngIf="!showBinValidation">{{errorMsg}}</p>
				<div *ngIf="type=='otp'" class="otp-input">

					<div id="divOuter" class="row align-center mar-top-20 relative ml-0 mr-0">
					  <div class="enter-otp mr-3">Enter OTP</div>
					  <div id="divInner" class="ml-3">
						<div class="otp-field">
							<input id="otp-field" #otpField class="form-control mt-0 mb-0" autocomplete="off"
							  (input)="removeLetterOTP();" max="6" maxlength="6" [(ngModel)]="otp1" />
							<div class="input-border flex">
							  <span class="num-1" [ngClass]="{'active' : otp1?.length == 0 || !otp1}"></span>
							  <span class="num-2" [ngClass]="{'active' : otp1?.length == 1}"></span>
							  <span class="num-3" [ngClass]="{'active' : otp1?.length == 2}"></span>
							  <span class="num-4" [ngClass]="{'active' : otp1?.length == 3}"></span>
							  <span class="num-5" [ngClass]="{'active' : otp1?.length == 4}"></span>
							  <span class="num-6" [ngClass]="{'active' : otp1?.length == 5}"></span>
							</div>
							<div class="just"></div>
							<div class="clear pointer" (click)="clearOtp();" *ngIf="!progress">
							  X
							</div>
							<div class="clear otp-verifying" *ngIf="progress">
							  <img class="h100 w-100" src="./assets/img/icons/spin.gif">
							</div>
						  </div>
					  </div>
					</div>
				  
					<p class="invalid-feedback mar-top-10" *ngIf="otpErrorMsg">{{otpErrorMsg}}</p>
					<p *ngIf="otpTimer!=180 && !resendActive" class="pad-top-16 pad-bot-20 m-0 field-message">Resend in
					  {{otpTimer | minuteSeconds}} minutes</p>
					<p *ngIf="resendActive" (click)="resendOTP()" class="pad-top-16 pad-bot-20 m-0 cursor resend-button pointer">Resend OTP</p>
				  </div>  
				<div class="signup-checkbox" *ngIf="!(!userAlreadyExist && mobile.valid && !checkUserLoader) && !showBinValidation">
					<p>By signing in, you agree to our <a href="/info/terms-and-conditions" target="_blank">Terms & Conditions</a> & <a href="/info/privacy-policy" target="_blank">Privacy Policy</a>.</p>
				</div>
			</div>
			<div class="model-close">
				<i (click)="onNoClick()" class="fa fa-close close pointer" aria-hidden="true"></i>
			</div>
		</div>
	</div>

</div>