import {
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../services/common-services";
import { Loader } from "../../services/loader";
import { User } from "../../services/user";
import { FormatTimePipe } from "../../services/formatTime";
import { UntypedFormBuilder } from "@angular/forms";
import "rxjs/add/observable/timer";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
import { ActivatedRoute } from "@angular/router";
declare var $: any;
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "./../../../../../environments/environment";
import { AuthenticationComponent } from "./../../authentication/authentication.component";
import { DataSharingService } from "./../../services/data-sharing.service";
import { appConstants } from "../../../../app.constants";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [FormatTimePipe],
})
export class HeaderComponent {
  @ViewChild("signinModalClose") myDiv1: ElementRef;
  @ViewChild("signupModalClose") myDiv2: ElementRef;
  @ViewChild("clone") template;
  @ViewChild("container", { read: ViewContainerRef }) container;
  email: any;
  currentUser: any;
  savedCards: any[];
  paramLink: any;
  sideMenuOpen = false;
  showEligibleCardsMobile: boolean;
  showEligibleCardsDesktop: boolean;
  protected appConstants = appConstants;
  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    public _commonService: CommonService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private loader: Loader,
    private user: User,
    private resolver: ComponentFactoryResolver,
    public dataS: DataSharingService
  ) {
    this.dataS.headerAuthenticated.subscribe((data: any) => {
      if (data) {
        localStorage.removeItem("access-token");
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("currentUser");
        this.currentUser = null;
      }
    });
  }

  ngOnInit() {
    this._commonService.getClientDetails();
    this.loader.userPresent.subscribe((status) => {
      if (status) {
        var user = this._commonService.getUser();
        if (user) {
          this.currentUser = JSON.parse(user);
        }
      }
    });
    var user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
    }

    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("site-header");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > 80) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment == "login") {
        this.route.queryParams.subscribe((params) => {
          if (params.refferer && params.refferer == "offers") {
            this.paramLink = params.reffererLink;
            let token = localStorage.getItem("access-token");
            if (token) {
              this.goToOffers();
            } else {
              this.openSignup();
            }
          }
        });
      }
    });
    this._commonService.listener.subscribe((state) => {
      if (window.innerWidth < 993) {
        if (state) {
          this.sideMenuOpen = state;
        }
        this.showEligibleCardsMobile = state;
      } else {
        this.showEligibleCardsDesktop = state;
      }
    });
  }

  handleOutsideClick() {
    if (window.innerWidth < 993) {
      this.showEligibleCardsMobile = false;
    } else {
      this.showEligibleCardsDesktop = false;
    }
    if (this._commonService.openEligibleCards.value) {
      this._commonService.toggleShowingEligibleCards();
    }
  }

  openSignup() {
    const dialogRef = this.dialog.open(AuthenticationComponent, {});
  }

  openSideMenu() {
    this.sideMenuOpen = true;
  }

  goToOffers() {
    let token = localStorage.getItem("access-token");
    let uid = localStorage.getItem("uid");
    let client = localStorage.getItem("client");
    var offersLink = environment.offersLink;
    if (this.paramLink) {
      offersLink = this.paramLink;
    }
    if (token) {
      window.location.href =
        offersLink +
        "?utm_source=xowhomedecorcampaign&utm_medium=sms,mbpush" +
        "&code=" +
        token +
        "&uid=" +
        uid +
        "&client=" +
        client;
    } else {
      window.location.href = offersLink + "";
    }
  }
}
