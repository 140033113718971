<app-header class="default-head" *ngIf="resetHeader"></app-header>

<section class="half-banner-golf">
    <div class="container">
        <div class="row">
            <div class="slider-content">
                <div class="golf-card-block-left">
                    <h1 style="color:white;">Airport Concierge Service</h1>
                    <p style="color:white;">Avail special VIP assistance services for a smooth and hassle-free airport
                        transfer</p>
                    <div class="send-inquiry-button">
                        <!-- <div class="btn_container">
							<button class="btn btn-primary" mat-button (click)="bookGolfFamiliar(true)">Familiar with the Game</button>
							<div class="desc">Book a Golf Game</div>
						</div> -->
                        <div class="btn_container">
                            <button class="btn btn-primary" mat-button (click)="bookAirportVipPrivileges(false)"
                                [disabled]="cardValidating">
                                Book a visit
                                <i class="ml-2 mt-auto mb-auto"
                                    [class]="cardValidating ? 'fa fa-spinner fa-spin bin-loader' : ''"></i>
                            </button>
                            <!-- <div class="desc">Book a Airport VIP Privilege</div> -->
                        </div>
                        <br>
                        <input type="hidden" id="golf-experience" value="false">
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <!-- <a href="" style="background-image: url("https://daks2k3a4ib2z.cloudfront.net/5907c9a38b10c85428c0bc77/5928567a8570da37ffccd207_icon_down_arrow.svg") "></a> -->
            </div>

        </div>
    </div>
    <div class="container">

        <div class="row slider-mobile" style="margin-top: 20px">
            <div class="slider-content" style="color:white;">Applicable for only Axis Bank Burgundy Private Credit Card,
                Axis Bank Magnus for Burgundy Credit Card and Axis Bank Reserve Credit Card , Axis Bank Executive Corporate Card
                and Axis Olympus Credit Card 
            </div>
        </div>
    </div>

    <div class="container">

        <div class="row slider-mobile" style="margin-top: 120px">
            <div class="slider-content"><a class="scroll-to-golf-privileges" (click)="scroll('listing-section')"
                    style="color:white;">List of eligible airports.</a>
            </div>
        </div>
    </div>
</section>
<section class="golf-content">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 benefits-section" id="benefits-section">
                <!-- <h3>Axis Bank Golf Program Benefits:</h3>
				<div class="card-list-on-golf-page">
					<ul class="list-styled">
						<li>Axis Bank Reserve Credit Cardholders enjoy UNLIMITED complimentary rounds of golf.</li>
						<li>Axis Bank SELECT Credit Cardholders enjoy 6 complimentary rounds of golf per year.</li>
						<li>Axis Bank Vistara Infinite Credit Cardholders enjoy 6 complimentary rounds of golf per year.</li>
						<li>Axis Bank Vistara Signature Credit Cardholders enjoy 3 complimentary rounds of golf per year.</li>
						<li>For the exclusive offer, terms and conditions apply. The applicable cards are as below.</li>
					</ul>
				</div>
				<div class="notice-under-card-list-golf-page"><b>*Applicable Cards:</b> Reserve Credit Card, SELECT Credit Card, Burgundy Debit Card, Miles &amp; More World Select Credit Card, Miles &amp; More World Credit Card, Vistara Infinite Credit Card, Vistara Signature Credit Card, Vistara Platinum Credit Card, Signature Credit Card &amp; Privilege Credit Card.</div>
				<br> -->
                <p class="airpot_discription"> With airport concierge services, Axis Bank brings you a seamless air travel experience with any airline of your choice. Get greeted at the airport by our authorized agents, navigate yourself through the check-in, security and immigration processes and travel care-free with our porter and buggy services. Book your services now!</p>
                <h3 id="listing-section" class="listing-heading">List of eligible airports</h3>
                <div class="card-list-on-golf-page airpot_list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="airport-program-box"
                                *ngFor="let airportList of airportDisplayList; let i = index">
                                <div class="airport-program-inner-box row row-15" *ngIf="i % 2 == 0">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <strong>{{airportList.city}}</strong>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <p *ngFor="let terminal of airportList.terminal">{{airportList.airport}} -
                                            {{terminal}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="airport-program-box"
                                *ngFor="let airportList of airportDisplayList; let i = index">
                                <div class="airport-program-inner-box row row-15" *ngIf="i % 2 != 0">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <strong>{{airportList.city}}</strong>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <p *ngFor="let terminal of airportList.terminal">{{airportList.airport}} -
                                            {{terminal}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <ul class="list-styled ">
						<li *ngFor="let airport of airportDisplayList" class="airport-list">
							<div *ngFor="let terminal of airport?.terminal" class="airport-list-block">
								<div class="airport-list-left">{{airport?.city}}</div>
								<div>{{airport?.airport}} - {{terminal}}</div>
							</div>
						</li>
					</ul> -->
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row">
                <div class="golf-program-details-footer">
                    <p>
                        Link for detailed terms and conditions <a href="./assets/Airport_concierge_services_TnC.pdf"
                            target="_blank">click here.</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>