import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CommonModuleModule } from '../common-module.module';
import { PaymentComponent } from './payment.component';
import { ModuleWithProviders, NgModule } from '@angular/core';

declare var Razorpay: any;
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModuleModule
  ],
  declarations: [PaymentComponent],
  exports: [PaymentComponent]
})
export class PaymentModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {

    return {
        ngModule: PaymentModule,
        providers: [
            {
                provide: 'env', // you can also use InjectionToken
                useValue: environment
            },
            {
              provide: 'Razorpay',
              useValue: Razorpay
            }
        ]
    };
  }
}
